.itp-edit-number-title {
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 0.8;
  font-size: 12px;
}

.itp-edit-number-subtitle {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-edit-number-card {
  padding-left: 1.2rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 1.2rem !important;
}

.itp-edit-number-label-1 {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-bottom: 4px;
}

.itp-edit-number-label-2 {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-edit-number-input {
  width: 12rem !important;
  margin-right: 2rem;
  padding-top: 16px !important;
}

.itp-edit-number-container-table {
  margin-top: 4px;
}

.itp-edit-number-button {
  width: 11rem;
  height: 2rem;
  margin-right: 1.5rem;
  margin-top: 12px;
}

.itp-edit-number-select {
  width: 11rem !important;
}

.itp-edit-number-button-2 {
  width: 15rem;
  height: 1.8rem;
  margin-right: 1.5rem;
  margin-top: 0.8rem;
}

.itp-edit-number-button-3 {
  width: 4rem;
  height: 1rem;
  margin-right: 1.5rem;
  margin-top: 0.8rem;
}

.basic-single.lead-management-funnel {
  margin-left: 4rem;
}

.z-1000  {
  z-index: 1000 !important;
}

.basic-single.itp-edit-number-ai-select {
  width: 12.5rem;
} 

.ringall-select {
  margin-right: 2rem;
}