.itp-livePanel-extensions__multple-call-container {
  position: relative;
  width: "100%";
  height: 2rem;
}

.itp-livePanel-extensions__multple-call-info {
  position: absolute;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f6f6f9;
  top: -3.5rem;
  left: -2rem;
  width: auto;
  overflow: auto;
  padding: 8px;
  white-space: normal;
}

.itp-livePanel-extensions__multple-call-container:hover
  > .itp-livePanel-extensions__multple-call-info {
  visibility: visible;
}

.h2r {
  height: 2rem;
}

.width-icon {
  width: 1.3rem;
  height: 1.3rem;
}

.itp-livePanel-users-actions {
  opacity: 0.6;
  cursor: pointer;
}

.itp-livePanel-users-actions.active {
  color: red;
  opacity: 1;
}

.itp-livePanel-user-actions-icon-container {
  position: relative;
  width: "100%";
  height: 2rem;
}

.itp-livePanel-user-actions-icon-hover {
  position: absolute;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f6f6f9;
  top: -2.5rem;
  left: -0.8rem;
  width: auto;
  overflow: auto;
  padding: 8px;
  white-space: normal;
  border: 1px solid grey;
}

.itp-livePanel-user-actions-icon-container:hover > .itp-livePanel-user-actions-icon-hover {
  visibility: visible;
}