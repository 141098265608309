.itp-livePanel-switch-filter-label {
  font-family: Montserrat;
  font-size: 12px;
  letter-spacing: 0px;
  color: #002340;
  margin-right: 8px;
  font-weight: 100 !important;
}

.itp-livePanel-switch-filter .react-switch-handle {
  width: 17px !important;
  height: 17px !important;
  top: 1px !important;
  left: 1px !important;
}
