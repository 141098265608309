.itp-custom-table-main-row {
  visibility: visible;
}

.itp-custom-table-main-row:hover {
  cursor: pointer;
  background-color: rgba(67, 112, 151, 0.2);
}

.itp-custom-table-main-td-styles {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}
