.ipt-login-left {
  /* height: calc(100vh - 20px); */
  height: 100vh;
  padding: 5vh 5vw !important;
}

.ipt-login-right {
  height: 100vh;
  background-image: linear-gradient(80deg,#7367f0,#9e95f5);
  bottom: 0;
  right: 0;
}

@media (max-width: 767px) {
  .ipt-login-right {
    display: none;
  }
}

.itp-login-left-form {
  margin-top: 200px !important;
}

.ipt-login-row {
  padding: 0px 0px 0px 0px !important;
}

.itp-login-logo-container {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.itp-login-logo {
  width: 50% !important;
  height: auto;
  margin-top: 40px !important;
  margin-bottom: 20px;
}

.itp-login-welcome {
  margin: 20px 0px 50px 0px;
  text-align: center;
  color: #9e9e9e;
}

.itp-login-login-text {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.itp-login-button {
  border-radius: 10px !important;
  background: #6D28D9 !important;
  width: 100% !important;
  min-width: 300px;
  margin-bottom: 20px;
  border-color: #2a5dc6 !important;
  border-style: none !important;
  height: 50px;
}

#google-button {
  text-align: center;
  margin-top: 20px;
}
.login-button-google-container {
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
}

.login-button-google {
  padding: 10px 20px;
  background-color: white;
  color: black;
  border-color: #ddd;
  border-style: solid;
  border-radius: 10px;
  border-width: 1px 1px 1px 1px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
}

.g-logo {
  height: 20px;
  margin-right: 10px;
}

.itp-login-input {
  height: 50px;
  border-radius: 10px !important;
  width: 100% !important;
  margin-bottom: 20px;
  min-width: 300px;
}

.itp-login-options {
  margin-bottom: 16px;
  min-width: 300px;
  display: flex;
  flex-wrap: wrap;
}

.itp-login-options-col {
  display: flex;
  align-items: center; /* This centers the children vertically */
  flex-basis: 50%; /* This ensures that each col takes up half the width of the container */
  padding: 0; /* Adjust or remove padding as needed */
}
.itp-login-signup {
  color: #437097;
  font-weight: bold;
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 14px;
  text-align: left !important;
  display: block;
  width: 100%; /* This ensures that the link takes up the full width of its container */
  margin: 0; /* This resets any default margin */
  
}

.itp-login-copyright {
  margin-top: 70px !important;
  font-size: 12px;
  color: #2e475dc7;
}

.itp-login-create-account {
  color: #a7a1a1;
  font-size: 14px;
  text-align: center;
}

.itp-login-forgot {
  color: #437097;
  font-weight: bold;
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 14px;
  text-align: right;
  display: block;
  width: 100%; /* This ensures that the link takes up the full width of its container */
  margin: 0; /* This resets any default margin */
  float: none;
}

.itp-login-sign-up {
  color: #437097;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  float: none;
  font-family: "Montserrat";
}

.itp-login-forgot:hover, .itp-login-sign-up:hover {
  color: #002340;
}

.itp-login-remember {
  font-size: 14px;
  color: #3a3a3a;
}

.itp-login-frame {
  position: relative;
  width: 100% !important;
  bottom: 0px !important;
  height: auto;
  bottom: 0px;
  right: 20%;
}

.itp-login-image-container {
  position: absolute;
  bottom: 0px !important;
  margin-bottom: 0px !important;
}

.itp-login-image-path-container {
  position: absolute;
  top: -40px !important;
  margin-top: 0px !important;
  right: 40px;
}

.checkboxitp {
  margin-right: 5px;
}

.itp-login-text {
  margin-left: 10px;
}

.itp-login-text {
  font-size: 14px;
  color: #2e475d;
}

.itp-login-right-title {
  font-size: 32px;
  display: inline;
}

.itp-login-right-title-peark {
  font-size: 18px;
}

.itp-login-button-span {
  margin-right: 8px;
}
