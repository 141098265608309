


.itp-text-back-funnel-details {
  text-align: left;
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
}

.itp-tex-back-container {
  margin-right: 10px;
  cursor: pointer;
  z-index: 9999;
}

.backToMainMenu{
  position: sticky;
  top: 70px;
  margin-left: 70px;
}

.itp-funnel-details-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #b7b7b71f;
  border: 1px solid #70707047 !important;
  border-radius: 8px !important;
  opacity: 1;
  width: 96.5%;
  margin-left: 20px;
}

.itp-funnel-details-title {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-bottom: 0;
}

.itp-funel-details-subtitle {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.container-date-range.funnel-details {
  width: 100%;
}

@media (min-width: 1868px) {
  .container-date-range.funnel-details {
    width: 70%;
  }
}

.itp-funnel-details-summary {
  border: 1px solid #70707040;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  overflow: auto;
}

.itp-funnel-details-summary-title {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-funnel-detaiñ-summary-earnings {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-funnel-detaiñ-summary-number {
  font: normal normal 600 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
  white-space: nowrap;
}

.itp-funnel-details-total-lead {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
}

.itp-funnel-details-separator {
  border: 1px solid #70707040;
  border-radius: 8px;
  opacity: 1;
  margin-left: 2rem;
  margin-right: 2rem;
}

.itp-funnel-details-row-summary {
  margin-left: 1px !important;
  margin-top: 20px !important;
}

.itp-funnel-details-lead-data {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
}

.itp-funnel-details-lead-data-number {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
}

.itp-funnel-details-total-leads {
  margin-top: 1rem;
}

.itp-funnel-details-leads-description {
  width: 100%;
}

@media (max-width: 561px) {
  .itp-funnel-details-leads-description {
    gap: 20px;
  }
}

.itp-funnel-details-summary.graphic {
  margin-left: 16px !important;
  flex: 0 0 auto !important;
  width: 39% !important;
}

@media (max-width: 2000px) {
  .itp-funnel-details-summary.graphic {
    margin-left: 0 !important;
    flex: 0 0 auto !important;
    width: 99% !important;
    margin-top: 20px;
    overflow: hidden !important;
  }
}

@media (max-width: 2000px) {
  .itp-funnel-details-summary {
    flex: 0 0 auto !important;
    width: 99% !important;
  }
}

@media (max-width: 575px) {
  .itp-funnel-details-summary.graphic {
    margin-left: 0 !important;
    flex: 0 0 auto !important;
    width: 97% !important;
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  .itp-funnel-details-summary {
    flex: 0 0 auto !important;
    width: 97% !important;
  }
}

@media (max-width: 415px) {
  .itp-funnel-details-summary.graphic {
    margin-left: 0 !important;
    flex: 0 0 auto !important;
    width: 96% !important;
    margin-top: 20px;
  }
}

@media (max-width: 415px) {
  .itp-funnel-details-summary {
    flex: 0 0 auto !important;
    width: 96% !important;
  }
}

.itp-funnel-details-lead-in-funel-title {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-bottom: 0;
}

.itp-funnel-details-lead-in-funel-subtitle {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-funnel-details-new-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.input-right-date {
  margin-right: 12px;
}

.funel-details-apply-filter {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.funnel-details-select-date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .funnel-details-select-date {
    justify-content: flex-start;
    margin-top: 8px;
    flex-wrap: wrap;
  }
}

@media (min-width: 1868px) {
  .funnel-details-select-date {
    margin-right: 16px;
  }
}
