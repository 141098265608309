.basic-single.ai-select-auto.error {
  border: 1px solid red;
  border-radius: 8px;
}

.basic-single.ai-select.error {
  border: 1px solid red;
  border-radius: 8px;
}

.select.error__control.css-13cymwt-control {
  border-color: red;
}

.select.error__control.select.error__control--is-focused.css-t3ipsp-control {
  border-color: red;
}
