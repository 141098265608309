.itp-avatar {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 50% !important;
  font-size: .857rem;
  font-weight: 600;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 1;
  overflow: visible;
  position: relative;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.itp-avatar-text {
  font-size: calc(14px);
  border-radius: inherit;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  mask-image: radial-gradient(#fff,#000);
}