.itp-chat-list {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* horizontal-offset vertical-offset blur-radius spread-radius color */
  border: 1px solid #f9f9f9;
  border-radius: 8px;
  opacity: 1;
  margin-left: 1.5rem;
  margin-top: 12px !important;
  height: calc(100vh - 86px) !important;
  max-height: 1040px;
  border-radius: 5px;
}

.infinite-scroll-component {
  /* height: calc(90vh - 110px) !important; */
  height: 100% !important;
  max-height: 1100px;
}

@media (min-width: 993px) and (max-width: 1100px) {
  .itp-chat-list__img-item {
    width: 30px !important;
    height: 30px !important;
    padding: 3px !important;
  }
  .rounded-circle {
    width: 30px !important;
    height: 30px !important;
    padding: 3px !important;
    font-size: 12px !important;
  }
  .itp-chat-list__col-item-info {
    margin-left: 7px !important;
  }
}

@media (min-width: 1299px) and (max-width: 1400px) {
  .itp-chat-list__img-item {
    padding: 3px !important;
    width: 35px !important;
    height: 35px !important;
  }

  .rounded-circle {
    font-size: 16px !important;
    padding: 3px !important;
  }
  .itp-chat-list__col-item-info {
    margin-left: 10px !important;
  }
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .itp-chat-list__img-item {
    padding: 3px !important;
    width: 35px !important;
    height: 35px !important;
  }

  .rounded-circle {
    font-size: 16px !important;
    padding: 3px !important;
  }
  .itp-chat-list__col-item-info {
    margin-left: -15px !important;
  }
}

@media (min-height: 1280px) {
  .itp-chat-list {
    height: 75rem;
  }
}

@media (min-height: 883px) and (max-height: 1279px) {
  .itp-chat-list {
    height: 60rem;
  }
}

@media (min-height: 800px) and (max-height: 882px) {
  .itp-chat-list {
    height: 47rem;
  }
}

@media (min-height: 760px) and (max-height: 799px) {
  .itp-chat-list {
    height: 38rem;
  }
}

@media (min-width: 1920px) {
  .itp-chat-list {
    flex: 0 0 auto !important;
    width: 20% !important;
    max-width: 455px !important;
  }
}

.itp-chat-list-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.85;
  padding-top: 20px;
  padding-left: 20px;
  height: 100px !important;
  max-height: 100px !important;
}

/* @media (min-width: 1400px) and (max-width: 1919px) {
  .itp-chat-list-header {
    height: 8%;
  }
} */

@media (min-width: 1920px) {
  .itp-chat-list-header {
    height: 10%;
  }
}

.itp-chat-list-header__text {
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000cc;
  opacity: 1;
  margin-bottom: 10px;
}

.itp-chat-list-header__select {
  background: rgba(255, 255, 255, 0.08) 0% 0% no-repeat padding-box;
  border: 0.6000000238418579px solid #ddd;
  border-radius: 8px;
  color: #6e6b7b;
  padding: 8px 8px 8px 12px;
  font-family: Montserrat;
  letter-spacing: 0px;
  width: 100%;
}

.itp-chat-list-header__select-option {
  background: #f6f7f8 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 0;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-chat-list-header__container-icon {
  padding: 10px;
}

.itp-chat-list-header__icon {
  color: 6D28D9;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.2s ease !important;
}

.rotate-icon {
  transform: rotate(45deg);
  transition: transform 0.2s ease !important;
  width: 1.7rem !important;
  height: 1.7rem !important;
}

.itp-chat-list-header__info-icon {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
}

.itp-chat-list-header__info-icon.add {
  margin-left: -3rem;
}

.itp-chat-list-header__info-icon.close {
  margin-left: -1rem;
}

.itp-chat-list-header__container-icon:hover > .itp-chat-list-header__info-icon {
  visibility: visible;
}

.itp-chat-list-body {
  height: 85%;
  cursor: pointer;
  transition: all 2s ease-in-out !important;
}

.itp-chat-list-item:hover {
  background-color: gainsboro;
  /* opacity: 0.5; */
}

.itp-chat-list-item.no-hover-options:hover {
  background-color: #f6f6f9;
}

/* Estilos para navegadores basados en WebKit (Chrome, Safari, etc.) */
.infinite-scroll-component::-webkit-scrollbar {
  width: 2px; /* Ancho del scroll */
  transition: width 1s ease; /* Transition for width change */
}

.infinite-scroll-component::-webkit-scrollbar-track {
  background: #8787874d; /* Color de fondo del track (la barra detrás del scroll) */
}

/* Hover effect styles on scrollbar */
.infinite-scroll-component:hover::-webkit-scrollbar {
  width: 7px; /* Increased width on hover */
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background: rgba(
    112,
    112,
    112,
    0.5
  ); /* Color del thumb (la barra de scroll en sí) */
  transition: background-color 1s ease; /* Transition for color change */
}

.infinite-scroll-component__outerdiv {
  flex-grow: 1;
  overflow: hidden;
  max-height: 1100px !important;
}

.infinite-scroll-component__outerdiv::-webkit-scrollbar {
  width: 3px;
}
.infinite-scroll-component__outerdiv::-webkit-scrollbar-thumb {
  background: #aeacac;
}

.itp-chat-list-item {
  margin-left: 0px !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
  min-height: 80px;
  padding-top: 10px;
  position: relative;
}

.itp-chat-list-item.active.row {
  background-color: rgba(115, 103, 240, 0.12);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 767px) {
  .itp-chat-list-item {
    cursor: pointer;
  }
}

.itp-chat-list__col-item-img {
  padding-top: 2px !important;
  margin-right: 7px !important;
  margin-left: 5px !important;
}

.itp-chat-list__img-item {
  width: 3rem;
  height: 3rem;
}

.itp-chat-list__col-item-info {
  position: relative;
}

@media (min-width: 1800px) {
  /* .itp-chat-list__col-item-info {
      flex: 0 0 auto !important;
      width: 90% !important;
    } */
}

@media (min-width: 1500px) and (max-width: 1799px) {
  /* .itp-chat-list__col-item-info {
      flex: 0 0 auto !important;
      width: 88% !important;
    } */
}

.itp-chat-list__chat-pinned {
  position: absolute;
  right: 3rem;
  top: 1.5rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.itp-chat-lit__notification {
  position: absolute;
  background-color: rgb(20, 98, 181);
  border-radius: 50%;
  height: auto;
  min-width: 1.5rem;
  right: 1rem;
  top: 1.5rem;
  min-height: 1.5rem;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffffcc;
  opacity: 1;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 4px;
}

.itp-chat-lit__dropdown {
  position: absolute;
  right: -0.5rem;
  top: 1rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  visibility: hidden;
}

.itp-chat-list-item:hover .itp-chat-lit__dropdown {
  visibility: visible;
}

.itp-chat-list__item-content {
  flex: 1; /* El contenido ocupa todo el espacio disponible */
  overflow: hidden; /* Ocultar el contenido que desborda */
}

.itp-chat-list__container-info-item {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}

.itp-chat-list__item-name {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  word-wrap: anywhere;
}

.itp-chat-list__no-data {
  font: normal normal normal 18px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  text-align: center;
  margin-top: 12px;
}

.itp-chat-list__item-date {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-top: 3px;
  white-space: nowrap;
}

.itp-chat-list__item-last-message {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  margin-top: 3px;
}

.itp-chat-list-item-missed {
  background: #1f3d571e 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: 0px !important;
  margin-top: 12px !important;
  margin-right: 0px !important;
  padding-left: 4px;
  padding-right: 8px;
}

@media (max-width: 767px) {
  .itp-chat-list-item-missed {
    cursor: pointer;
  }
}

.itp-chat-list-item-missed__title {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin-bottom: 8px;
}

.itp-chat-list__item-last-missed {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #d60c0c;
  opacity: 1;
}

.itp-chat-list-circle {
  background: #002340 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: 2.5rem;
  top: 0.5rem;
}

.itp-chat-list__add-chat-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  margin-right: 1.6rem;
}

.react-tel-input.itp-chat-list__phone-input-add-chat {
  width: 85%;
  margin-left: 22px;
}

.itp-chat-list__add-chat-text {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-left: 22px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.itp-chat-list__search-input {
  outline: none;
  border: 0;
  width: 100%;
}

.itp-chat-list__font-select-option {
  font: normal normal normal 14px Instrument Sans;
}

.itp-chat-list__chat-pinned {
  position: absolute;
  right: 3rem;
  top: 1.5rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.itp-chat-lit__dropdown {
  position: absolute;
  right: -0.5rem;
  top: 1rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  visibility: hidden;
}

.itp-chat-list-item:hover .itp-chat-lit__dropdown {
  visibility: visible;
}

/* Resto del código CSS existente ... */

/* Estilos específicos para elementos con data-fake="true" */
/* Agrega estilos generales para tu componente ChatList */

/* Estilos específicos para el esqueleto */
.itp-chat-list-item[data-fake="true"] {
  position: relative;
  background: linear-gradient(
    90deg,
    #f0f0f0,
    #e0e0e0,
    #f0f0f0
  ); /* Gradiente para el esqueleto */
  background-size: 200% 100%;
  animation: loadingAnimation 1.5s infinite; /* Animación opcional para el esqueleto */
}

/* Animación opcional para el esqueleto */
@keyframes loadingAnimation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Estilos para la imagen del esqueleto (puedes ajustar según tu diseño) */
.itp-chat-list-item[data-fake="true"] .itp-chat-list__img-item {
  width: 40px; /* Ancho de la imagen del esqueleto */
  height: 40px; /* Altura de la imagen del esqueleto */
  border-radius: 50%; /* Bordes redondeados para la imagen del esqueleto */
  background-color: #dcdcdc; /* Color de fondo para la imagen del esqueleto */
  opacity: 0;
}

.itp-chat-list-item[data-fake="true"] .itp-chat-list__container-info-item {
  opacity: 0;
}

.itp-chat-list-item[data-fake="true"] .itp-chat-list__item-last-message {
  opacity: 0;
}

.itp-chat-list-hover-options {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 38px;
  display: flex;
  gap: 4px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .itp-chat-list-hover-options {
    top: -3rem;
    right: -3rem;
  }
}

.itp-chat-list-item:hover .itp-chat-list__img-item {
  opacity: 0.7;
}

.itp-chat-list-item:hover .itp-chat-list__container-info-item {
  opacity: 0.7;
}

.itp-chat-list-item:hover .itp-chat-list__item-last-message {
  opacity: 0.7;
}

.itp-chat-list-item:hover .itp-chat-lit__notification {
  opacity: 0.7;
}

.itp-chat-list-item:hover .itp-chat-lit__dropdown {
  opacity: 0.7;
}

.itp-chat-list-item:hover .itp-chat-list-hover-options {
  visibility: visible;
}

.itp-chat-list-tag-component-hover {
  visibility: hidden;
  position: relative;
  /* right: -8.5rem; */
  top: -1rem;
  z-index: 2;
  width: 100%;
  margin-left: 2.3rem;
}

.itp-chat-list-item:hover .itp-chat-list-tag-component-hover {
  visibility: visible;
}

@media (min-width: 1920px) {
  .itp-chat-list-tag-component-hover {
    margin-left: 1.5rem;
    width: 98%;
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .itp-chat-list-tag-component-hover {
    margin-left: 0;
  }
}

@media (max-width: 1300px) {
  .itp-chat-list-tag-component-hover {
    margin-left: 1.8rem;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .itp-chat-list-tag-component-hover {
    margin-left: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .itp-chat-list-tag-component-hover {
    margin-left: 0.8rem;
  }
}

@media (min-width: 877px) and (max-width: 991px) {
  .itp-chat-list-tag-component-hover {
    top: -4rem;
    margin-left: 5.7rem;
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 876px) {
  .itp-chat-list-tag-component-hover {
    margin-left: 0.8rem;
  }
}

.itp-chat-list-menu-option-hover-name {
  word-wrap: anywhere;
  white-space: nowrap;
}

.rounded-circle-default {
  background-color: #7367f01f;
  align-items: "center";
  align-self: "start";
  display: "flex";
  font-size: 30px;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  color: #6d28d9;
  width: 50px;
  height: 50px;
}

.itp-chat-list-separator {
  color: #6e6b7b;
  font-size: 14px;
  margin-top: 1rem;
  margin-left: 1rem;
}
.itp-chat-list-error-number {
  text-align: left;
  white-space: nowrap;
  margin-left: 4px;
}

.ipt-chat-list-item {
  padding: 8px 11px;
  display: flex;
}

.ipt-chat-list-item.active {
  background-image: linear-gradient(to bottom,rgba(115, 103, 240, 0.5),#9e95f5) !important;
}

.ipt-chat-list-item.unread {
  background: #f1f1f1;
}

.ipt-chat-list-item:hover {
  background: #f1f1f1;
}

.itp-chat-list-item__avatar-checkbox {
  visibility: visible;
  position: relative;
}

.itp-chat-list__item-checkbox {
  visibility: hidden;
  margin-top: 12px;
  margin-right: 0px;
  display: inline-block;
  left: -20px;
  padding-left: 1.8rem;
  position: absolute;
  z-index: 1;
  min-height: 1.45rem;
  print-color-adjust: exact;
}

.itp-chat-list__item-avatar {
  visibility: visible;
}

.ipt-chat-list-item:hover > .itp-chat-list-item__avatar-checkbox > .itp-chat-list__item-checkbox {
  visibility: visible;
}

.ipt-chat-list-item.loading:hover > .itp-chat-list-item__avatar-checkbox > .itp-chat-list__item-checkbox {
  visibility: hidden !important;
}

.ipt-chat-list-item:hover > .itp-chat-list-item__avatar-checkbox > .itp-chat-list__item-avatar {
  visibility: hidden;
}

.ipt-chat-list-item.loading:hover > .itp-chat-list-item__avatar-checkbox > .itp-chat-list__item-avatar {
  visibility: visible !important;
}

.itp-chat-list-item__info {
  margin-top: 0.3rem;
  padding: 0 0 0 0.75rem;
  width: 12rem;
  flex-grow: 1 !important;
}

.itp-chat-list-tem__info-name {
  margin-bottom: 0;
  margin-top: 0;
  font-family: Montserrat,Helvetica,Arial,serif;
  font-size: 10pt;
  color: #4f4f4f;
  word-wrap: anywhere;
  font-weight: 500;
}

.itp-chat-list-tem__info-name.loading {
  width: 7rem;
}

.itp-chat-list-item-info__lastMessage {
  font-family: Instrument Sans;
  font-size: 9pt;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  width: 15rem;
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itp-chat-list-item__date-container {
  position: relative;
  white-space: nowrap;
}

.itp-chat-list-item__date {
  margin-bottom: 0.25rem;
  float: right !important;
  white-space: nowrap;
  font: normal normal normal 10px/14px Instrument Sans;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-chat-list-item__unreadMessages {
  right: 0;
  float: right;
  background-color: rgb(20, 98, 181);
  padding-top: 5px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-bottom: 0.3rem;
  position: absolute;
  bottom: 0;
  font-size: 8pt;
  border-radius: 10rem;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
}


.itp-chat-list-item__avatar-not-contact {
  width: 35px;
  height: 35px;
  background-color: #7367f01f;  
  font-size: 22px;
  text-align: center;
  border-radius: 50%;
  color: #6d28d9;
  margin-left: 5px;
  margin-top: 5px;
}

.form-check-input.itp-chat-list-checkbox {
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid rgb(109, 40, 217);
}

.btn-light.itp-chat-list-bulkButton {
  min-height: 1.5rem;
  font-size: 11px;
  min-width: 3rem;
  width: auto;
}

.itp-chat-list-bulkActions__button.tags {
  width: 10px;
  height: 10px;
  margin-right: 1px;
}

.itp-chat-list-bulkActions__button.pin {
  width: 13px;
  height: 13px;
}

.itp-chat-list-bulkActions__button.unpin {
  width: 12px;
  height: 12px;
}

.itp-chat-list-bulkActions__button.archived {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  margin-top: -1px;
}
.itp-chat-list-bulkActions__button.unarchived {
  width: 10px;
  height: 10px;
  margin-right: 2px;
  margin-top: -2px;
}

.form-check-input.itp-chat-list-checkbox.checkall {
  margin-left: 1.25rem
}

.itp-chat-list-bulkActions__button.delete {
  margin-top: -0.25rem;
}