.disabled-action {
  color: gray !important;
}

.disabled-action.hover-action {
  position: relative;
}

.action-dropdown-hover {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
  top: -2rem;
}

.disabled-action.hover-action:hover > .action-dropdown-hover {
  visibility: visible;
}

.action-dropdown-hover-text {
  text-align: left;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin: 10px;
  white-space: nowrap;
}

.dropdown-menu.show {
  display: block;
}