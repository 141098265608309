.active > .page-link,

.page-link.active {
  background-color: #6D28D9 !important;
  border-color: transparent !important;
  color:#fff !important;
}

.page-link {
  color: #6D28D9 !important;
  border-color: #b9b9b9 !important;
  font-size: 14px !important;
}