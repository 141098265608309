.resizable-vertical {
  resize: vertical;
  overflow: auto;
  min-height: auto;
  height: 400px;
  max-height: 100%; /* Altura máxima inicial */
  scrollbar-width: thin; /* Para Firefox */
  scrollbar-color: #888 #f1f1f1; /* Para Firefox */
}

/* Estilos del scrollbar para navegadores basados en WebKit (Chrome, Safari) */
.resizable-vertical::-webkit-scrollbar {
  width: 12px;
}

.resizable-vertical::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.resizable-vertical::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.resizable-vertical::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Estilos del scrollbar para Firefox */
.resizable-vertical {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* Estilos del scrollbar para Internet Explorer y Edge */
@-ms-viewport {
  scrollbar-face-color: #888;
  scrollbar-shadow-color: #f1f1f1;
  scrollbar-highlight-color: #f1f1f1;
  scrollbar-3dlight-color: #f1f1f1;
  scrollbar-darkshadow-color: #f1f1f1;
  scrollbar-track-color: #f1f1f1;
  scrollbar-arrow-color: #888;
}
