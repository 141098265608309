.itp-stages-drag-icon {
  color: #437097;
}

.itp-stages-delete-icon {
  margin-top: -8px;
}

.itp-stages-add-container {
  background: #0023400a 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  padding: 8px 8px 12px 8px;
  margin-bottom: 2rem;
}

.itp-stage-add-title {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-stage-add-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.btn-primary.itp-stage-add-button {
  width: 8rem;
}

.itp-stages-add-container.step2 {
  background: #a2c7e630 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  padding: 8px 8px 12px 8px;
  margin-bottom: -8px;
}

.form-control-itp.itp-label-input__input.itp-add-stage-input {
  background-color: transparent;
}

.itp-add-stage-buttons {
  display: flex;
  gap: 8px;
  margin-bottom: 2rem;
}

.form-select-itp.itp-add-stage-select {
  width: 50%;
}