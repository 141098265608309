.itp-add-number-border.campaign-wizard-border {
  width: 5rem;
}

.itp-add-number-border.campaign-wizard-border.long {
  width: 6rem;
}

.itp-add-number-border.campaign-wizard-border.schedule {
  width: 8.5rem;
}