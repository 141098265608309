.itp-table-container-allf.card.customize {
  overflow: inherit!important;
  width: 960px!important;
  margin: auto;
}

.form-letter-property {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Instrument Sans', sans-serif;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  text-align: left;
}