.itp-contact-details-modal .modal-dialog {
  max-width: 90vw;
  height: 80vh;
  margin: 5vh auto;
}

.itp-contact-details-modal .modal-content {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.itp-contact-details-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
  padding: 0.75rem 1.5rem 0;
  background-color: #fff;
  margin-bottom: 0;
}

.itp-contact-name {
  font-size: 1.4rem;
  font-weight: 600;
}

.itp-contact-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6366f1;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
}

.itp-header-button {
  border: 1px solid #e5e7eb;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
}

.itp-header-button.delete-button.btn-light {
  color: rgb(208, 2, 27);
}

.itp-header-button:hover {
  background-color: #f9fafb;
  border-color: #d1d5db;
  color: #111827;
}

/* Tabs styling */
.itp-contact-details-tabs {
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 0 1rem 0 0;
  margin: 1rem 0;
  position: relative;
}

.itp-contact-details-nav {
  padding: 0;
  border-bottom: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-left: 0.5rem;
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  bottom: -1px;
}

.itp-contact-details-nav .nav-item {
  margin-left: 0.5rem; /* Add space between the tabs */
}

.itp-contact-details-nav .nav-item .nav-link {
  color: #4b5563;
  border: none;
  padding: 0.5rem 1.5rem 0.5rem;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: 500;
  transition: color 0.2s ease;
  margin-bottom: -1px;
}

.itp-contact-details-nav .nav-item .nav-link:hover {
  color: #6366f1;
}

.itp-contact-details-nav .nav-link:hover {
  color: #000 !important;
}

.itp-contact-details-nav .nav-item .nav-link.active {
  color: #6D28D9;
  font-weight: 600;
  background-color: transparent;
  border-bottom: 2px solid #6D28D9;
  padding-bottom: 0.5rem;
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
}

.itp-tab-count {
  display: inline-block;
  background-color: #e5e7eb;
  color: #4b5563;
  border-radius: 4px; /* Make the count badges less rounded */
  padding: 0.1rem 0.5rem;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  font-weight: 500;
}

.itp-contact-details-nav .nav-item .nav-link.active .itp-tab-count {
  background-color: #e9daff;
  color: #6D28D9;
}

/* Main content area */
.itp-contact-section {
  display: flex;
  height: calc(100% - 60px); /* Reducir para compensar el espacio eliminado */
  padding: 0;
  background-color: #f9f9f9;
}

.itp-contact-sidebar {
  width: 30%;
  padding: 0.75rem 1.5rem 1rem;
  border-right: 1px solid #e0e0e0;
  background: #fff;
  overflow-y: auto;
}

/* Webkit browsers (Chrome, Safari, Edge) scrollbar styling */
.itp-contact-sidebar::-webkit-scrollbar {
  width: 2px;
  /* Ancho del scroll */
  transition: width 1s ease;
  /* Transition for width change */
}

.itp-contact-sidebar::-webkit-scrollbar-track {
  background: #8787874d;
  /* Color de fondo del track (la barra detrás del scroll) */
}

.itp-contact-sidebar:hover::-webkit-scrollbar {
  width: 7px;
  /* Increased width on hover */
}

.itp-contact-sidebar::-webkit-scrollbar-thumb {
  background: rgba(112,
      112,
      112,
      0.5);
  /* Color del thumb (la barra de scroll en sí) */
  transition: background-color 1s ease;
  /* Transition for color change */
}

.itp-contact-sidebar h6 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
}

.itp-about-contact-title {
  font-size: 0.95rem;
  font-weight: 600;
  color: #333;
}

.itp-contact-content-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: -5px;
  padding-top: 0;
  background-color: #fff;
}

.itp-contact-main {
  width: 100%;
  padding: 0.5rem 1.5rem 1.5rem;
  overflow-y: auto;
  background-color: #f9f9f9;
  flex: 1;
}

/* Webkit browsers (Chrome, Safari, Edge) scrollbar styling for main content */
.itp-contact-main::-webkit-scrollbar {
  width: 2px;  /* Very thin default width */
  background-color: transparent;
}

.itp-contact-main::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.15);  /* Always slightly visible */
  border-radius: 2px;
}

/* Show slightly wider scrollbar on hover */
.itp-contact-main:hover {
  scrollbar-width: thin;  /* Firefox */
  scrollbar-color: rgba(0,0,0,0.25) transparent;  /* Firefox - darker on hover */
}

.itp-contact-main:hover::-webkit-scrollbar {
  width: 4px;  /* Slightly wider on hover but still thin */
}

.itp-contact-main:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.25);  /* Darker on hover */
}

.itp-field-label {
  font-size: 0.85rem;
  font-weight: 500;
  color: #666;
  margin-bottom: 0.25rem;
}

.itp-contact-sidebar .form-group {
  margin-bottom: 1.25rem;
}

.itp-contact-sidebar .form-control {
  background-color: #f5f7fb;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 0.9rem;
  padding: 0.5rem 0.75rem;
  color: #333;
}

.itp-contact-sidebar .form-control:disabled {
  background-color: #f9f9fb;
  opacity: 0.8;
  cursor: not-allowed;
}

/* React Select styling to match other inputs */
.itp-contact-sidebar .basic-single {
  margin-bottom: 0.5rem;
}

.itp-contact-sidebar .basic-single .select__control {
  background-color: #f5f7fb;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 0.9rem;
  min-height: 38px;
  color: #333;
  box-shadow: none;
}

.itp-contact-sidebar .basic-single .select__control--is-focused {
  border-color: #6D28D9;
  box-shadow: 0 0 0 1px #6D28D9;
}

.itp-contact-sidebar .basic-single .select__control--is-disabled {
  background-color: #f9f9fb;
  opacity: 0.8;
  cursor: not-allowed;
}

.itp-contact-sidebar .basic-single .select__placeholder {
  color: #999;
  font-size: 0.9rem;
}

.itp-contact-sidebar .basic-single .select__single-value {
  color: #333;
  font-size: 0.9rem;
}

.itp-contact-sidebar .basic-single .select__menu {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.itp-contact-sidebar .basic-single .select__option {
  font-size: 0.9rem;
  padding: 8px 12px;
}

.itp-contact-sidebar .basic-single .select__option--is-focused {
  background-color: #f5f7fb;
}

.itp-contact-sidebar .basic-single .select__option--is-selected {
  background-color: #6D28D9;
  color: #fff;
}

.itp-contact-sidebar .basic-single .select__indicator-separator {
  background-color: #e0e0e0;
}

.itp-contact-sidebar .basic-single .select__dropdown-indicator {
  color: #999;
}

.itp-contact-sidebar .basic-single .select__dropdown-indicator:hover {
  color: #666;
}

.itp-contact-sidebar .basic-single .select__clear-indicator {
  color: #999;
}

.itp-contact-sidebar .basic-single .select__clear-indicator:hover {
  color: #666;
}

.itp-contact-sidebar .basic-single .select__multi-value {
  background-color: #e9daff;
  border-radius: 4px;
}

.itp-contact-sidebar .basic-single .select__multi-value__label {
  color: #6D28D9;
  font-size: 0.85rem;
}

.itp-contact-sidebar .basic-single .select__multi-value__remove {
  color: #6D28D9;
}

.itp-contact-sidebar .basic-single .select__multi-value__remove:hover {
  background-color: #d3b9f7;
  color: #6D28D9;
}

/* Additional styles for AsyncSelect dropdown indicator when loading */
.itp-contact-sidebar .basic-single .select__indicators .select__spinner {
  color: #6D28D9;
}

.itp-edit-button {
  color: #6366f1;
  font-size: 0.85rem;
  text-decoration: none;
}

.itp-edit-button:hover {
  color: #4f46e5;
  text-decoration: none;
}

/* Activity items styling */
.itp-contact-date-header {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.itp-contact-activity-item {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  padding: 1rem;
}

.itp-contact-activity-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.itp-contact-team-tag {
  background-color: #f0f0f0;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
}

.itp-contact-activity-time {
  color: #999;
  font-size: 0.8rem;
}

.itp-contact-activity-content p {
  margin-bottom: 0;
  font-size: 0.9rem;
}

.itp-contact-search-bar {
  position: relative;
  margin-bottom: 1.5rem;
}

.itp-contact-search-bar input {
  border-radius: 20px;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  background-color: #f5f5f5;
  border: none;
  width: 100%;
  font-size: 0.9rem;
}

.itp-search-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  z-index: 1;
}

/* History items styling */
.itp-contact-history {
  list-style: none;
  padding: 0;
}

.itp-contact-history li {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.itp-tag-badge {
  display: inline-block;
  background: #dfe7fd;
  color: #2c3e50;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75rem;
  margin-right: 5px;
}

/* Notes tab styling */
.itp-contact-notes-tab {
  background-color: #f9f9f9;
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;
}

/* Activity tab styling */
.itp-contact-activity-tab {
  background-color: #f9f9f9;
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;
}

/* Custom Fields Section */
.itp-custom-fields-section {
  border-top: 1px solid #e0e0e0;
  padding-top: 1.5rem;
}

/* Override CollapsePanel styles for Notes and ContactHistory */
.itp-contact-notes-tab .itp-note-panel,
.itp-contact-activity-tab .itp-note-panel {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.itp-contact-notes-tab .accordion-button,
.itp-contact-activity-tab .accordion-button {
  padding: 0;
  margin-bottom: 1rem;
}

.itp-contact-notes-tab .accordion-body,
.itp-contact-activity-tab .accordion-body {
  padding: 0;
}

/* Summary cards in All tab */
.itp-summary-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  width: 48%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.itp-summary-card:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.itp-summary-card h6 {
  color: #495057;
  margin-bottom: 12px;
}

.itp-summary-card p {
  font-size: 18px;
  font-weight: 500;
}

.itp-contact-summary-section h6 {
  font-size: 16px;
  font-weight: 600;
  color: #343a40;
}

.itp-contact-summary-section .text-muted {
  font-size: 14px;
  line-height: 1.5;
}

/* Hidden data loaders */
.itp-contact-main > div[style*="display: none"] {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

/* Estilos para el componente PhoneInput */
.itp-phone-container {
  width: 100%;
  display: flex !important;
  height: auto !important;
}

.itp-phone-input {
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem 0.375rem 50px !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #f5f7fb !important;
  background-clip: padding-box !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 6px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.itp-phone-dropdown-button {
  border: 1px solid #e0e0e0 !important;
  border-right: none !important;
  background-color: #f5f7fb !important;
  border-radius: 6px 0 0 6px !important;
  width: auto !important;
  min-width: 45px !important;
  padding: 0 0 0 5px !important;
}

.itp-phone-dropdown {
  max-height: 200px !important;
  overflow-y: auto !important;
  width: 300px !important;
}

.itp-phone-input:focus {
  color: #495057 !important;
  background-color: #f5f7fb !important;
  border-color: #6366f1 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(99, 102, 241, 0.25) !important;
}

/* Asegurar que el contenedor del teléfono tenga el espacio adecuado */
.form-group .react-tel-input {
  width: 100% !important;
}

/* Estilos para la sección de tags */
.itp-contact-details-modal .itp-component-tag-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.itp-contact-details-modal .itp-component-tag-width {
  width: 100%;
}

.itp-contact-details-modal .itp-component-tag-img {
  margin-right: 10px;
  cursor: pointer;
}

.itp-contact-details-modal .MuiChip-root {
  margin: 2px;
  height: 24px;
  font-size: 12px;
}

.itp-contact-details-modal .MuiAutocomplete-tag {
  margin: 2px;
  max-width: 100%;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  height: 24px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #e0e0e0;
  border-radius: 16px;
  white-space: nowrap;
  padding: 0 12px;
  outline: 0;
  text-decoration: none;
  border: 0;
  vertical-align: middle;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
}

/* Estilos para el botón de guardar */
.itp-save-button {
  min-width: 80px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.itp-save-button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.itp-save-button:active:not(:disabled) {
  transform: translateY(1px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Estilo para el botón deshabilitado durante la carga */
.itp-save-button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

/* Estilos para el mensaje de error */
.itp-contact-sidebar .alert-danger {
  font-size: 0.85rem;
  border-radius: 6px;
  border-left: 3px solid #dc3545;
}

.itp-contact-sidebar .alert {
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
}

/* Lead Information Section */
.itp-lead-section {
  padding: 15px;
  border-radius: 8px;
  background-color: rgba(240, 240, 245, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.itp-lead-section h6 {
  color: #434343;
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.itp-lead-section .itp-field-label {
  font-weight: 500;
  color: #555;
}

/* Override select styles in lead section */
.itp-lead-section .basic-single {
  background-color: white;
}

/* Section dividers */
.itp-section-divider {
  border-top: 1px solid #e0e0e0;
  margin: 1.5rem 0;
  padding-top: 1.5rem;
}

.itp-section-title {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.25rem;
}

/* Enhanced UI styles for contact details */
.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

/* Icon styling for contact details */
.text-primary svg {
  transition: transform 0.2s ease;
}

.text-primary svg:hover {
  transform: scale(1.2);
}

/* Tooltip styling */
.tooltip {
  font-size: 0.8rem;
  opacity: 0.95 !important;
}

.tooltip .tooltip-inner {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.4rem 0.7rem;
}

/* Tag styling enhancements */
.rounded-pill {
  transition: all 0.2s ease;
}

.rounded-pill:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Improved tag styling for read-only mode */
.tags-container {
  padding: 0;
  margin: 0;
}

.tag-pill {
  transition: all 0.2s ease;
  cursor: default;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem !important;
  line-height: 1;
  height: 28px;
}

.tag-pill:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Additional styles for specific tag colors to ensure readability */
.tag-pill[style*="background-color: #f7e528"],
.tag-pill[style*="background-color: #8fe1e7"],
.tag-pill[style*="background-color: #8de257"],
.tag-pill[style*="background-color: rgb(237, 246, 253)"] {
  color: #333 !important;
}

/* Default tag color style */
.tag-pill-default {
  background-color: rgb(237, 246, 253) !important;
  color: #333 !important;
}

.tags-container:empty:after {
  content: "No tags";
  color: #888;
  font-size: 0.9rem;
}

/* Clean layout for opt-out checkbox in read-only mode */
.opt-out-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #f0f0f0;
}

.opt-out-container .form-check-input {
  margin-right: 0.5rem;
}

.opt-out-container .itp-field-label {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

/* Assigned user styling */
.assigned-user-avatar {
  width: 30px;
  height: 30px;
  min-width: 30px;
  background-color: #6366f1;
  color: #fff;
  transition: all 0.2s ease;
}

.assigned-user-avatar:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Contact info card styling */
.contact-info-card {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
}

.contact-info-card:hover {
  background-color: #f0f0f5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.contact-info-icon {
  margin-right: 0.75rem;
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6366f1;
}

/* Ensure icons are properly positioned */
.position-absolute.end-0.top-50.translate-middle-y {
  margin-top: 0 !important;
  transform: translateY(-50%) !important;
  z-index: 10 !important;
}

/* Adjust input padding when icons are present */
.form-control.pe-4,
input.pe-4 {
  padding-right: 2.5rem !important;
}