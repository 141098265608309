.itp-select-add-lead-label {
  text-align: left;
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-add-lead-select {
  margin-top: 6px;
  width: 90%;
}

.itp-lead-button {
  width: 4rem;
  height: 2rem;
  margin-top: 1.5rem;
}

.itp-add-lead-new-contact {
  margin-top: 8px;
  width: 80%;
}

.basic-single.add-lead-select {
  margin-top: 4px;
  width: 80%;
}

.add-lead-container-field {
  margin-bottom: 12px;
}

.add-lead-new-contact {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.select__placeholder.css-1jqq78o-placeholder {
  font: normal normal normal 14px Instrument Sans !important;
  letter-spacing: 0px !important;
  color: #3a3a3a99 !important;
  opacity: 1 !important;
}


.css-1dimb5e-singleValue {
  color: #535353 !important;
  font-size: 14px;

}

.css-tj5bde-Svg {
  color: #535353;
}

.select__control.css-13cymwt-control:active {
  border: 0.30000001192092896px solid #1976d2 !important;
}

.select__control.css-13cymwt-control:focus {
  border: 0.30000001192092896px solid #1976d2 !important;
}
