.itp-modal-addTag-color-container {
  display: flex;
  align-items: flex-end;
  height: 30px;
}

.form-check-input.itp-modal-addTag-checkColor {
  margin-left: 10px;
  margin-bottom: 4px;
}

.itp-modal-addColor-pickerColor-container {
  display: block;
  position: relative;
  z-index: 2;
}

.itp-modal-addTag-container {
  min-height: 80px;
  width: 100%;
  margin-bottom: -2rem;
}