.fullscreen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
}

.fullscreen-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-container img {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.timestamp {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  color: white;
  font-size: 12px;
}

.close-button {
  /* position: absolute;
  top: 10px;
  right: 10px; */
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: white;
  cursor: pointer;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}
