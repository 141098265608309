.input-icon {
  width: 20px;
  height: 20px;
  background-image: url("../../../../../../assets/icons/email.svg"); /* Ruta de la imagen del icono */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 4px;
}

.itp-container-input {
  display: flex;
  border-bottom: 1px solid #437097;
}

.flag-dropdown {
  border-radius: 8px 0 0 8px !important;
}

.itp-add-contact-manually-label {
  text-align: left;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 8px;
}

.form-control {
  border-radius: 16px !important;
  box-shadow: none !important;
}

.form-control:focus {
  border: 1px solid lightblue;
}

.form-control:focus + .flag-dropdown {
  border: 1px solid lightblue;
}

.itp-add-contact-phone-input {
  margin-top: 12px;
  margin-bottom: 12px;
}

.select__control.css-13cymwt-control {
  border-radius: 8px !important;
}

.basic-single.itp-add-contact-manually-select {
  width: 18.5rem !important;
}

.select__indicator-separator.css-1u9des2-indicatorSeparator {
  display: none;
}

.select__control.select__control--is-focused.select__control--menu-is-open.css-t3ipsp-control {
  border-radius: 8px !important;
  border: 0.30000001192092896px solid transparent !important;
}

.select__control.select__control--is-focused.css-t3ipsp-control {
  border-radius: 8px !important;
  border: 0.30000001192092896px solid transparent !important;
}

#react-select-3-placeholder {
  white-space: nowrap;
}

/* #react-select-5-listbox.select__menu.css-1nmdiq5-menu:hover {
  background-color: red;
} */
