body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon-hover {
  cursor: pointer;
}

.icon-hover:hover {
  transform: scale(1.2);
}

.icon-hover:active {
  transform: scale(0.8);
}

.icon-hover-table {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 7px;
}

.hover-container {
  position: relative;
}

.hover-container .icon-visible-on-hover {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

.hover-container:hover .icon-visible-on-hover {
  visibility: visible;
  opacity: 1;
}

.icon-hover-table:hover {
  transform: scale(1.2);
}

.icon-hover-table:active {
  transform: scale(0.8);
}

.btn-light {
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #6d28d9;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  opacity: 1;
  background-color: #fff;
  min-width: 100px;
  min-height: 2.5rem;
  font-family: Montserrat;
}
.btn-light:hover {
  background-color: rgb(233, 233, 233);
}

.btn-primary {
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #6d28d9 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  border: 0;
  display: inline-block;
  padding-right: 15px;
  min-width: 100px;
  min-height: 40px;
}

.btn-primary:hover {
  background-color: #5c23b8;
}

.btn-primary:disabled {
  background-color: #ddd;
}

.btn-danger {
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: red 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  border: 0;
  display: inline-block;
  padding-right: 15px;
  min-width: 100px;
  min-height: 40px;
}

.btn-danger:hover {
  background-color: rgba(255, 0, 0, 0.8);
}

.form-select-itp {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-color: #b9b9b9;
  border-radius: 8px;
  display: block;
  width: 100%;
  padding: 8px 8px 8px 12px;
  font-family: "Montserrat";
  letter-spacing: 0px;
  color: #6e6b7b;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236D28D9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  margin-top: 5px;
  height: 40px;
}

.form-select-itp.error {
  border: 1px solid red !important;
}

.form-control-itp {
  border: none;
  border-bottom: 1px solid #dbe8f3;
  outline: none;
  font: normal normal normal 14px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  width: 100%;
  background-color: transparent;
  padding-bottom: 5px;
}

.form-control-itp::placeholder {
  color: #3a3a3a99;
}

.form-control-itp.error {
  border-bottom: 1px solid red !important;
}

::file-selector-button {
  border: 0 !important;
  margin-top: 18px;
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  background: #437097 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0;
  min-width: 5rem;
  min-height: 2rem;
  display: inline-block;
  cursor: pointer !important;
}

.form-control-itp:focus {
  border-bottom: 1px solid #1976d2;
}

.form-select-itp:focus {
  border: 1px solid #1976d2;
}

.form-select-itp.select-skeleton {
  color: transparent;
  background-image: none;
  border-color: transparent;
  /* background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out; */

  animation: shimmer 1.5s infinite ease-in-out;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 80rem 100%;
}

.icon-skeleton {
  /* background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out; */
  color: transparent;

  animation: shimmer 1.5s infinite ease-in-out;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 80rem 100%;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.itp-component-tag-container {
  display: flex;
  gap: 12px;
  border-bottom: 1px solid #ddd;
}

.itp-component-tag-width {
  width: 100%;
}

.itp-component-tag-img {
  margin-top: 4px;
  width: 1.5rem;
}

.itp-component-tag-label {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root::before {
  border: 0 !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root::after {
  border: 0 !important;
}

.itp-component-tag-container:focus-within {
  border-bottom: 1px solid #002340;
}

.itp-container-label-input {
  border-bottom: 1px solid #ddd;
  margin: 0 0 16px 0 !important;
}

.itp-container-label-input.loading {
  border: 0;
  align-items: center;
}

.itp-container-label-input.error {
  border-bottom: 1px solid red !important;
}

.itp-container-label-select {
  margin: 0 0 16px 0 !important;
}

.itp-container-label-select.b-bottom {
  border-bottom: 1px solid #ddd;
}

.itp-container-label-select.b-bottom:focus-within {
  border-bottom: 1px solid #002340;
}

.itp-container-label-select.loading {
  align-items: center;
}

.itp-container-label-input:focus-within {
  border-bottom: 1px solid #002340;
}

.itp-label-input-col {
  padding-left: 0 !important;
}

.itp-label-input__label {
  text-align: left;
  font-family: "Montserrat";
  font-size: 14px;
  letter-spacing: 0px;
  color: #0c0c0c;
  opacity: 1;
  margin-bottom: 0;
}

.itp-label-input__input {
  border: 0 !important;
  width: 100%;
  font-family: "Montserrat";
  color: #0c0c0c;
}

.form-control-itp.itp-label-input__input:focus {
  border-bottom: 0 !important;
}

.btn-primary.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-check-input.border {
  border: 1px solid gray !important;
}

.modal-header {
  color: #437097 !important;
}

.react-tel-input .form-control {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 0.30000001192092896px solid #ddd !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}

.react-tel-input .form-control:focus {
  border: 0.30000001192092896px solid #b0b0b0 !important;
}

.react-tel-input .flag-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #b0b0b0;
  opacity: 1;
}

.react-tel-input .flag-dropdown:focus {
  border: 0.30000001192092896px solid #b0b0b0 !important;
}

.react-tel-input .flag-dropdown:active {
  border: 0.30000001192092896px solid #b0b0b0 !important;
}

.react-tel-input .selected-flag:hover {
  background-color: transparent !important;
}

.alert-background-default {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 1.5rem;
  height: 2.5rem;
}

.alert-background-primary {
  background: rgb(67, 125, 180) 0% 0% no-repeat padding-box;
  border-radius: 1.5rem;
  height: 1.5rem;
}

.alert-background-danger {
  background: rgb(190, 0, 0) 0% 0% no-repeat padding-box;
  border-radius: 1.5rem;
  height: 1.5rem;
}

.alert-background-success {
  background: rgba(0, 255, 0, 0.15) 0% 0% no-repeat padding-box;
  border-radius: 1.5rem;
  height: 2.5rem;
}

.itp-alert-text {
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-left: 3px;
  margin-right: 3px;
}

.itp-alert-data-default {
  float: left;
  margin-left: 5px;
  margin-top: 4px;
}

.itp-alert-data-primary {
  float: left;
  margin-left: 5px;
  margin-top: 4px;
  color: white;
}

.itp-alert-data-danger {
  float: left;
  margin-left: 5px;
  margin-top: 4px;
  color: white;
}

.itp-alert-data-success {
  float: left;
  margin-left: 5px;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.8);
}

.itp-alert-icon-default {
  float: left;
  width: 18px !important;
  height: 18px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.itp-alert-icon-primary {
  color: white;
  width: 18px !important;
  height: 18px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.itp-alert-icon-danger {
  color: white;
  width: 18px !important;
  height: 18px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.itp-alert-icon-success {
  color: rgba(0, 0, 0, 0.8);
  width: 18px !important;
  height: 18px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.bgc-transparent {
  background-color: transparent !important;
}

.w-90 {
  width: 90% !important;
}

.cursor-help {
  cursor: help;
}

.itp-test {
  height: 100%;
  margin-top: 1.5rem;
}

.spacer-10 {
  height: 15px;
  width: 100%;
  overflow: auto;
}

.css-6od3lo-MuiChip-label {
  font-family: "Montserrat";
}

.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-tag {
  border-radius: 0.5rem;
  border-width: 1;
}

.itp-accordion-header-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3a;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}

.itp-accordion-header-subtitle {
  color: rgba(58, 58, 58, 0.8);
  font: 14px / 19px Instrument Sans;
}

.accordion-button {
  background-color: transparent !important;
  box-shadow: none !important;
}

.select.select-prefix-error__control.css-13cymwt-control {
  border: 1px solid red;
}

.itp-container-label-input.itp-container-label-input-error {
  border-bottom: 1px solid red !important;
}

.select__indicator.select__clear-indicator.css-1xc3v61-indicatorContainer {
  padding: 0;
}

.select__indicator.select__dropdown-indicator.css-1xc3v61-indicatorContainer {
  padding: 0;
}

.my-multiselect__indicator.my-multiselect__clear-indicator.css-1xc3v61-indicatorContainer {
  padding: 0;
}

.my-multiselect__indicator.my-multiselect__dropdown-indicator.css-1xc3v61-indicatorContainer {
  padding: 0;
}

.v-visible {
  visibility: visible !important;
}

.v-hidden {
  visibility: hidden !important;
}

.w-90 {
 width: 90% !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mt-3 {
  margin-top: -1rem !important;
}

.-mt-4 {
  margin-top: -1.5rem !important;
}

.-mt-5 {
  margin-top: -2rem !important;
}