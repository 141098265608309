.itp-show-filter-button {
  min-width: 7.2rem;
  width: auto;
  height: 2.1rem;
}

@media (max-width: 514px) {
  .itp-show-filter-button {
    min-width: 9.5rem;
    width: auto;
    height: 2.1rem;
  }
}
