.itp-ai-select {
  width: 100%;
  margin-top: -1.2rem;
}

.ai-company-title {
  color: #333333;
  font-family: "Instrument Sans", sans-serif;
  font-size: 16px;
  text-align: left;
}

.ai-company-descp {
  color: #9e9e9e;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: "Instrument Sans", sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}

.w-100.ai-textarea {
  resize: none;
}

.itp-custom-table-container-search-input.centered.ai-search {
  margin-left: 30px !important;
}

.itp-table-container-centered.ai-table {
  margin-top: -2rem;
}

.itp-ai-menu-questions-table-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -1rem;
}

.btn-primary.itp-ai-menu-questions-table-button {
  width: 8rem;
}

.itp-ai-menu-questions-table-container {
  /* overflow: hidden auto; */
  margin-left: -1.8rem;
}

.itp-container-label-select.itp-ai-followup__addSchedle-container {
  margin-top: -2rem !important;
  flex-wrap: wrap;
}

.itp-ai__ai-agent-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.itp-ai__ai-agent-fields.space-field {
  margin-top: 1.5rem;
}

.itp-ai__ai-agent-field {
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

.itp-ai__ai-agent-container-input {
  display: flex;
  justify-content: flex-end;
}

.itp-ai__ai-agent-container-input.input-auto {
  max-width: 50%;
}

.basic-single.ai-select {
  width: 10rem;
}

.basic-single.ai-select-auto {
  width: auto;
}

.itp-ai__ai-agent-integrations-table {
  padding: 0 !important;
}

.itp-ai__ai-agents-prompt-icon {
  margin-left: -0.25rem;
}