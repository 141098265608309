.actions-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}

@media (min-width: 939px) {
  .actions-container {
    gap: 1rem;
  }
}

.actions-left {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.actions-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (min-width: 924px) and (max-width: 1067px) {
  .actions-right {
    margin-left: auto;
  }
}

@media (min-width: 1196px) {
  .actions-right {
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1067px) {
  .actions-right {
    margin-left: 0;
    align-items: stretch; /* Valor predeterminado de flex items, cambialo según necesites */
    display: flex;
    gap: 10px;
  }
}

@media (max-width: 834px) {
  .actions-right {
    margin-left: 0;
    align-items: stretch; /* Valor predeterminado de flex items, cambialo según necesites */
    display: flex;
    gap: 10px;
  }
}
