.itp-integrations__card-item.card {
  cursor: pointer;
  border: 1px solid rgba(109, 40, 217, 0.3);
  box-shadow: 0px 4px 8px #949494;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transición suave */
}

.itp-integrations__card-item.card:hover {
  background-color: rgba(67, 112, 151, 0.2);
  transform: scale(1.05); /* Crece un 5% */
  box-shadow: 0px 8px 16px #949494; /* Sombra más intensa al hacer hover */
}

.itp-integrations__card-item.card:hover {
  background-color: rgba(67, 112, 151, 0.2);
}

.itp-integrations__card-item-name {
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0px;
  color: #6D28D9;
}

.itp-integrations__card-container {
  margin-top: 1rem !important;
}

.itp-integrations__card-item-img {
  width: 7rem;
  height: 7rem;
}