.itp-button-jobs-error {
  margin-left: 8px !important;
  margin-bottom: 8px !important;
}

.import-contacts-container {
  margin-left: 12px;
}

.label-input-add-contact-list {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.label-add-contact-list {
  margin-right: 12px;
}

.input-add-contact-list {
  width: 80% !important;
}

.add-contact-list-buttons {
  display: flex;
  gap: 8px;
}

.import-contact-nex-button {
  margin-bottom: 12px;
  margin-top: 8px;
}

.import-contact-container-table {
  margin-left: -2.1rem !important;
}
