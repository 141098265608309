.row.itp-timeGroupDetails-addEditTimeDate-clock-row {
  margin-top: -2.5rem;
  margin-bottom: 2rem;
}

.clock-container {
  display: inline-flex;
  align-items: center;
  color: #333;
  font-family: "Montserrat";
  font-size: 1rem;
}

.clock-icon {
  margin-right: 4px;
  margin-left: 1rem;
}

.clock-time {
  font-weight: bold;
}
