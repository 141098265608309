.hideWebphone {
    transform: translateX(110%); /* Move it off-screen */
    opacity: 0;
    pointer-events: none;
}

.showWebphone {
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
}