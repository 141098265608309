
.board {
  width: 240px;
  height: 120px;
}

h6,
p {
  font-family: sans-serif;
  font-size: 13px;
}

.itp-dashboard-chart__card-body.card-body {
  padding-left: 0;
  padding-bottom: 0;
}

.itp-dashboard-card-info-voicemail {
  margin-left: 3rem;
}

@media (min-width: 1920px) {
  .itp-dashboard-card-info-voicemail {
    margin-left: -6rem;
  }
}
