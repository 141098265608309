.select-container {
  position: relative;
  display: inline-block;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 4;
  background-color: #fff;
}

.select-option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  justify-content: space-between;
}

.select-option:hover {
  background-color: #f5f5f5;
}

.select-option input[type="checkbox"] {
  margin-left: 8px;
}
