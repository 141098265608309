.itp-signUp {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
}

.itp-signUp.image-step {
  top: 0;
}

.card.itp-signUp__card {
  padding: 24px;
  border-radius: 0;
}

.itp-signUp__card-body__title {
  color: #333333;
  font-family: "Montserrat";
  font-size: 26px;
  text-align: left;
  line-height: 1px;
  padding: 5px;
  margin-bottom: 16px;
}

.itp-signUp__card-body__textInfo {
  color: #333333;
  font-family: "Montserrat";
  font-size: 14px;
  text-align: left;
  line-height: 1;
  padding: 5px;
}

.itp-signUp__card-body__textInfo--number {
  color: #3f51b5;
  font-family: "Source Sans Pro";
  font-size: 26px;
  text-align: center;
  line-height: 1px;
  padding: 5px;
}

.itp-signUp__card-body__form-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.itp-signUp__card-body__form__input {
  color: #000000;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  height: 3rem;
}

.itp-signUp__card-body__form__input:disabled {
  background-color: #e0e0e0;
}

.itp-signUp__card-body__form__confirm {
  display: flex;
  gap: 1rem;
}

.itp-signUp__card-body__form__confirm__text {
  color: #333333;
  font-family: "Montserrat";
  font-size: 14px;
  text-align: left;
  line-height: 1;
  padding: 5px;
}


.itp-signUp__verification-code-container {
  display: flex;
  gap: 12px;
}

.itp-signUp__verification-code-input {
  color: #000000;
  font-family: "Source Sans Pro";
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  padding: 5px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 3rem;
}

.itp-signUp-image {
  width: 100vw;
  height: 95vh;
}

.itp-signUp-image.congrat {
  position: absolute;
  top: 0;
}

.card-body.itp-signUp__card-body {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 3rem;
}

.itp-signUp__card-body__textInfo--last {
  color: #333333;
  font-family: "Source Sans Pro";
  font-size: 16px;
  text-align: center;
  line-height: 1px;
  padding: 5px;
}

.itp-signUp__card-body__title--message {
  color: #757575;
  font-family: "Source Sans Pro";
  font-size: 20px;
  text-align: left;
  line-height: 1px;
  padding: 5px;
}

.itp-signUp__card-body__textInfo--message {
  color: #333333;
  font-family: "Source Sans Pro";
  font-size: 16px;
  text-align: left;
  line-height: 1;
  padding: 5px;
}

.itp-signUp__card-body__textInfo--message-span {
  color: #3f51b5;
}

.itp-signUp__card-body__textInfo--message-skip {
  color: #333333;
  font-family: "Source Sans Pro";
  font-size: 12px;
  text-align: center;
  line-height: 1px;
  padding: 5px;
}

.itp-signUp__card-body__form__button-text {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  width: 100%;
  
}

.list{
  color: black;
  outline: 0;
  box-shadow: 0px 1px 0px 0.08rem rgba(50, 31, 219, 0.25);
  position: absolute;
  width: calc(100% - 2px);
  z-index: 100;
  background-color: white;
  border: 1px solid rgb(216,219,224);
  margin: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: scroll;
 }

.list__options {
  padding: 10px 12px;
  cursor: pointer;
}

.list__options:hover {
  background-color: #d5a5f0;
}

.text-danger.text-center.already-account {
  font-size: 15px;
  font-family: "Montserrat";
  color: #333333;
}

.text-danger {
  font-size: 15px;
  font-family: "Montserrat";
  color: #333333;

}

/* New class for sign up buttons */
.btn-primary-signup {
  width: auto; /* Adjust this value as needed */
  min-width: 120px; /* Example value, adjust based on your design */
  height: 50px;
  font-weight: 600;
}