.h-card-drag {
  height: 6.5rem !important;
}

.row.itp-dragdrop-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  margin-left: 0;
  margin-right: 0;
}

.itp-dragdrop-column-title {
  margin-bottom: 0px;
  margin-top: 16px;
  white-space: nowrap;
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-dragdrop-space-columns {
  margin-right: 20px;
}

.col-4.itp-dragdrop-actions {
  padding-left: 2rem;
}

.card.itp-dragdrop-card-container {
  width: 80%;
  margin-left: 12px;
  padding: 0px 0px 0px 4px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  border-left: 4px solid #437097;
  height: auto !important;
}

.itp-dragdrop-row-card {
  margin-top: 12px !important;
}

.card-body.itp-dragdrop-cardbody-container {
  padding: 0;
}

.nav-link:focus,
.nav-link:hover {
  color: transparent !important;
}

.nav-link.btn-secondary.dropdown-toggle.btn.btn-primary {
  background: transparent;
  box-shadow: none;
}

.itp-dragdrop-label1 {
  margin-bottom: 0;
  line-height: 1;
  font-size: 15px;
}

.itp-dragdrop-smallLabel1 {
  font-size: 0.6rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

.itp-dragdrop-smallLabel2 {
  font-size: 0.6rem;
}

.mt-dragdop {
  margin-top: 2px;
}

.itp-dragdrop-label2 {
  margin-bottom: 10px;
  margin-top: 10px;
  color: rgba(140, 140, 140, 0.9);
  font-size: 11px;
}

.mb-dragdrop {
  margin-bottom: 2rem !important;
}

.itp-dragdrop-column-container {
  background: transparent linear-gradient(154deg, #1f3d5714 0%, #3a3a3a03 100%)
    0% 0% no-repeat padding-box;
  border: 1px dashed #70707029;
  border-radius: 8px;
  opacity: 1;
}

.itp-drapdrop-subtitle {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

@media (min-width: 1200px) {
  .itp-drapdrop-subtitle {
    white-space: nowrap;
  }
}

.itp-dragdrop-cardbody-title {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 8px;
}

.itp-dragdrop-cardbody-subtitle {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-bottom: 0;
  margin-right: 8px;
  margin-left: 8px;
}

.itp-dragdrop-cardbody-labelFooter {
  font: normal normal 600 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-left: 8px;
}

.itp-dragdrop-tag {
  text-align: center;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  background: #51a9f01a 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  margin-right: 8px;
  display: inline-block;
  min-width: 4rem;
  width: auto;
  margin-bottom: 12px;
}

.itp-dragdrop-date {
  font: normal normal normal 10px/14px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.mr-subtitle {
  margin-right: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.itp-dragdrop-cardbody-title.cursor-pointer:hover {
  text-decoration: underline;
}