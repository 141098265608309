.itp-panel-right-content.open {
  top: 7rem;
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 0.30000001192092896px solid #70707073;
  opacity: 1;
  height: calc(100% - 7.3rem);
  width: 30%;
  right: 0;
  transform: translateX(0%);
  transition: transform 0.2s;
  z-index: 3;
  overflow: auto;
  bottom: 0;
}

.itp-panel-right-content.hidden {
  transform: translateX(100%);
  transition: transform 0.2s;
  margin-top: 70px;
  position: fixed;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0;
  border: 0.30000001192092896px solid #70707073;
  opacity: 1;
  height: calc(100% - 70px);
  width: 30%;
  right: 0;
  z-index: 3;
  overflow: auto;
  bottom: 0;
}
