.select-date-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 575px) {
  .select-date-container {
    justify-content: flex-start;
    margin-top: 8px;
    flex-wrap: wrap;
  }
}

@media (min-width: 1868px) {
  .select-date-container {
    margin-right: 16px;
  }
}

.date-range-name {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin-right: 4px;
  white-space: nowrap;
}

.date-range-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #437097;
  border-radius: 18px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 6px 0 6px 12px;
  width: 100%;
}

@media (min-width: 1868px) {
  .date-range-container {
    width: 70%;
  }
}

.search-date-range-label {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin-right: 4px;
}

.search-date-range-input {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  border: 0;
  outline: none;
  background-color: transparent;
}

.search-input-right-date {
  margin-right: 12px;
}
