.itp-edit-contact-container-custom-field {
  position: relative;
}

.itp-edit-contact-custom-field-actions {
  position: absolute;
  background-color: #ffffff;
  width: 6.5rem;
  visibility: hidden;
  top: -3rem;
  /* border: 1px solid #bebfc1; */
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  height: auto;
  box-shadow: #8d8a8a 0px 0px 3px;
}

.itp-edit-contact-container-custom-field:has(.itp-label-input__label:hover)
  > .itp-edit-contact-custom-field-actions,
.itp-edit-contact-custom-field-actions:hover {
  visibility: visible;
}

.itp-label-input__label.option {
  cursor: pointer;
}

.itp-label-input__label.option:hover {
  background-color: rgb(245, 245, 242);
}

.itp-label-border-bottom-property {
  border-bottom: #e3dede solid 1px;
}
