.itp-hoverInfo-container {
  position: relative;
}

.itp-hoverInfo-content {
  position: absolute;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f6f6f9;
  width: auto;
  overflow: auto;
  padding: 8px;
  white-space: normal;
  border: 1px solid gray;
  /* transition: all ease 0.1s; */
}

.itp-hoverInfo-container:hover > .itp-hoverInfo-content {
  visibility: visible;
}

.itp-hoverInfo-container.click-row:hover > .itp-hoverInfo-rowData {
  color: #002340;
}

.itp-hoverInfo-p {
  white-space: nowrap;
  margin-bottom: 0;
}
