.container-table-contact-list {
  margin-top: 16px;
  margin-bottom: 20px;
}

.select-checkbox-responsive.select-container {
  visibility: visible;
}

@media (max-width: 991px) {
  .select-checkbox-responsive.select-container {
    margin-top: 0;
  }
}

.btn-light.itp-show-filter-button.itp-show-filterButton-responsive, 
.btn-primary.itp-addButton-responsive,
.itp-container-search-input.itp-searchBoxFilter-responsive {
  visibility: visible;
}

@media (max-width: 847px) {
  .btn-light.itp-show-filter-button.itp-show-filterButton-responsive, 
  .btn-primary.itp-addButton-responsive {
    margin-top: 0.5rem;
  }
}

@media (max-width: 561px) {
  .itp-container-search-input.itp-searchBoxFilter-responsive {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
}

.tabs-icons {
  font-size: 17px !important;
  margin-right: 0.25rem;
}