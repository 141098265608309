.itp-data-report-card-data {
  height: 3rem !important;
}

.itp-date-report-date {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-right: 2rem;
  white-space: nowrap;
}

.itp-date-report-range-date {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-date-report-arrow-separator {
  margin-left: 12px;
  margin-right: 12px;
}
