
@media (max-width: 1049px) {
  .select-container {
    margin-top: 12px;
  }
}

.select-header {
  cursor: pointer;
  display: block;
  width: auto;
  min-width: 150px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 400;
  color: #6D28D9;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236D28D9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  appearance: none;
  border: 0.6000000238418579px solid #ddd;
  border-radius: 8px;
  opacity: 1;
  border-color: #b9b9b9;
}

.select-header.skeleton {
  /* background-color: #e0e0e0;
  animation: pulse 1.5s infinite ease-in-out; */
  border: 0;
  color: transparent;
  background-image: none;

  animation: shimmer 1.5s infinite ease-in-out;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 80rem 100%;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

/* @keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0;
  }
  100% {
    background-color: #e0e0e0;
  }
} */

.select-header:hover {
  background-color: #fff;
  border-radius: px;
}

.select-header.active-menu {
  background-color: #fff;
  border-radius: 8px;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 4;
  background-color: #fff;
}

.select-option {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  justify-content: space-between;
}

.select-option:hover {
  background-color: #f5f5f5;
}

/* .select-option.selected {
    background-color: transparent;
  } */

.select-option input[type="checkbox"] {
  margin-left: 8px;
}
