body {
  overflow: hidden visible !important;
}

.webphone-component{
  position: fixed;
  z-index: 100000;
  left: calc(50% - 127px);
  top: 100px;
  /* width: 100%;
  height: 100%;
  background: red; */
}

.reconnectedAction{
  text-decoration: underline;
  cursor: pointer;
  margin-left: 20px;
  font-weight: 600;
  }