.itp-add-number-border {
  border: 2px solid rgb(128, 128, 128, 0.5);
  width: 10rem;
}

.itp-add-number-border.active {
  border: 2px solid #002340;
}

.itp-edit-number-container-second-step {
  margin-left: 8px;
}

.mt-3rem {
  margin-top: 3rem !important;
}