.itp-header {
  background-color: #ffffff;
  color: blue;
  padding: 35px;
  z-index: 1;
  height: 50px !important;
  border: 1px solid #f9f9f9;
  margin-top: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-left: 30px;
  margin-right: 23px !important;
  position: sticky;
  top: 0;
  transition: all 0.5s;
}

.itp-logo {
  font-weight: bold;
  padding: 20px;
}

.itp-logo-img {
  width: 133px;
}

.itp-content {
  display: flex;
  align-items: center;
  height: 100%;
}

.itp-left-section {
  display: flex;
  align-items: center;
  flex: 1;
}

.itp-text {
  margin-right: 10px;
  font-family: Montserrat;
  font-size: 22px;
  color: #6e6b7b;
  font-weight: 600;
}

.itp-search {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.itp-search {
  margin-right: 5px;
}

.itp-icons {
  display: flex;
  align-items: center;
  margin-right: 0px;
  margin-left: 10px;
}

.itp-icon:hover {
  background: #ffffff39;
}

.itp-icon {
  margin-right: 10px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.itp-icon.menu {
  display: none;
}

.itp-profile {
  display: flex;
  align-items: center;
}

.itp-profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.itp-profile-details {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}

.itp-profile-name {
  font-size: 14px;
}

.itp-profile-email {
  font-size: 12px;
  color: #ffffffb8;
}

.itp-header-bar {
  height: 30px;
  border: 1px solid #ffffff24;
}

@media (max-width: 991px) {
  .itp-search {
    display: none;
  }
}

@media (max-width: 991px) {
  .itp-icon.menu {
    display: flex;
    transform: rotate(180deg);
    transition: all 0.5s;
  }
}

@media (max-width: 991px) {
  .itp-icon.menu.hidden {
    display: flex;
    transform: rotate(0deg);
    transition: all 0.5s;
  }
}

@media (max-width: 644px) {
  .itp-left-section,
  .itp-header-bar {
    display: none;
  }
}

@media (max-width: 644px) {
  .itp-content {
    justify-content: space-between;
  }
}

@media (max-width: 509px) {
  .itp-profile-details {
    display: none;
  }
}

@media (max-width: 387px) {
  .itp-logo-img {
    width: 100px;
  }
}

.rounded-circle-header {
  border-radius: 50% !important;
  padding: 16px !important;
}

.mt-3.mt-lg-0.dropdown-menu.dropdown-menu-end.show {
  z-index: 99999;
}

.itp-header-balance-container {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.itp-header-balance-number {
  color: rgb(0, 255, 0);
  font-family: Montserrat;
}

.itp-header-balance-text {
  font-size: 12px;
  font-family: Montserrat;
  color: #6e6b7b;
}

.itp-header-balance-usd {
  font-family: Montserrat;
  color: #6e6b7b;
}

.itp-header-container-clock-icon {
  position: relative;
}

.itp-header-click-icon-hover {
  visibility: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  margin-top: 4px;
  z-index: 2;
}

.itp-header-container-clock-icon:hover > .itp-header-click-icon-hover {
  visibility: visible;
}


.itp-header-click-icon-name {
  text-align: left;
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
  margin: 10px;
  white-space: nowrap;
}

.itp-header__toggle-dropdown {
  border-radius: 50%;
  padding: 16px;
  background-color: rgba(115, 103, 240, 0.12);
  align-items: center;
  align-self: start;
  display: flex;
  justify-content: center;
  text-align: center;
}