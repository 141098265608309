.container-input-range {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.3px solid #437097;
  border-radius: 18px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  width: auto;
  max-width: 100%;
}

.input-range-label {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  margin-right: 4px;
}

.input-range-separator {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  border: 0;
  margin-left: 12px;
  margin-right: 12px;
}

.input-range-input {
  font: normal normal normal 12px/16px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a99;
  opacity: 1;
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0 8px 0 0; /* Añadimos padding a la derecha para separar el texto del icono */
  margin: 0; /* Elimina el margen por defecto */
  min-width: 0; /* Asegúrate de que no haya un ancho mínimo que cause separación */
  width: 90px; /* Ancho fijo para los inputs */
}

/* Clase específica para inputs de tipo fecha */
.input-range-date {
  font-family: 'Instrument Sans', sans-serif;
  text-overflow: ellipsis;
  width: 110px !important; /* Forzamos el ancho para asegurar que la fecha sea visible */
}

/* Estilo específico para inputs de tipo fecha y otros con iconos nativos */
input[type="date"].input-range-input,
input[type="time"].input-range-input,
input[type="datetime-local"].input-range-input,
input[type="week"].input-range-input,
input[type="month"].input-range-input {
  padding-right: 10px; /* Más padding para inputs con iconos nativos */
}

/* Ajuste para navegadores webkit (Chrome, Safari, etc.) */
input::-webkit-calendar-picker-indicator {
  margin-left: 8px; /* Espacio adicional antes del icono */
  opacity: 0.7; /* Hacemos el icono un poco más sutil */
}

/* Aseguramos que el texto de la fecha sea completamente visible */
input[type="date"].input-range-input::-webkit-datetime-edit {
  padding: 0;
  margin: 0;
}

input[type="date"].input-range-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

/* Ajuste para Firefox */
@-moz-document url-prefix() {
  input[type="date"].input-range-input {
    padding-right: 15px;
  }
}