.itp-call-transcription__call-summary {
  width: 100%;
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px 10px 8px 12px;
}

.itp-call-transcription__call-summary-title {
  color: #002340;
  font-family: Montserrat;
  font-size: 10px;
  text-align: left;
}

.itp-call-transcription__call-summary-description {
  color: #002340;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  margin-bottom: 0;
}

.itp-call-transcription__call-description {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  display: flex;
  margin-top: 2.5rem;
  padding: 10px 12px;
  overflow: auto hidden;
}

.itp-call-transcription__call-description.small {
  width: 50%;
}

@media (min-width: 365px) and (max-width: 425px) {
  .itp-call-transcription__call-description.small {
    width: 60%;
  }
}

@media (max-width: 364px) {
  .itp-call-transcription__call-description.small {
    width: 70%;
  }
}

.itp-call-transcription__call-description-name {
  color: #000000;
  font-family: Montserrat;
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
}

.itp-call-transcription__call-description-info {
  color: #000000;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
}

.itp-call-transcription__call-description-info.light {
  color: #009688;
}

.itp-call-transcription__call-description-separator {
  border: 1px solid #bdbdbd;
}

.itp-call-transcription__download {
  display: flex;
  cursor: pointer;
  margin-top: 2rem;
}

.itp-call-transcription__download-text {
  color: #002340;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  cursor: pointer;
}

.itp-call-transcription__download:hover {
  text-decoration: underline;
}

.itp-call-transcription__transcription {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.itp-call-transcription__transcription-title-container {
  border-bottom: 1px solid #e0e0e0;
}

.itp-call-transcription__transcription-title {
  color: #000000;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  margin: 0.25rem 0 0.25rem 0.75rem;
}

.itp-call-transcription__transcription-text-container {
  background: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px;
  margin: 1.3rem 0.5rem 0 0.5rem;
}

.itp-call-transcription__transcription-text-title {
  color: #002340;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
}

.itp-call-transcription__transcription-text-title.agent {
  color: #9c27b0;
}

.itp-call-transcription__transcription-text-description {
  color: #002340;
  font-family: Montserrat;
  font-size: 12px;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
