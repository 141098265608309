.audio-settings{
    background: #002340;
    color: white;
    z-index: 1000;
    width: 700px;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid white;
}

.audio-settings-close{
    display: flex;
    justify-content: flex-end;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background: rgba(0, 0, 0, 0.2);
    color:white;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    color: white;
}

.nav-link{
    color: white;
}

.selects-audio-settings {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}