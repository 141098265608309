.itp-10dlc-arrow-down {
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: rgba(58, 58, 58, 0.5) !important;
}

.campaign-toolip-icon {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
}

.campaigns-toolip {
  visibility: hidden;
  text-align: left;
  font: 12px / 15px "Instrument Sans";
  letter-spacing: 0px;
  color: rgb(0, 35, 64);
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  border: 0.3px solid rgba(112, 112, 112, 0.45);
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  padding: 6px;
  right: 0;
  top: -1.5rem;
}

.campaign-toolip-icon:hover + .campaigns-toolip {
  visibility: visible;
}