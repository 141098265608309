.itp-audio-message-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.itp-audio-message {
  display: flex;
  gap: 0.5rem;
  margin-top: 0 !important;
  align-items: center;
  width: auto !important;
}

.itp-audio-message__play-content {
  background-color: #fff;
  border-radius: 50%;
  width: 3rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.itp-audio-message__play-content.secondary {
  background-color: #7367f0;
}

.itp-audio-message__play-loading {
  width: 1.8rem;
  height: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.35rem;
}

.itp-audio-message__play-icon {
  color: #437097;
  width: 1.8rem !important;
  height: 1.8rem !important;
}

.itp-audio-message__play-icon.secondary {
  color: #fff;
}

.itp-audio-message__input {
  position: relative;
  z-index: 0;
}

input[type="range"].itp-audio-message__input {
  -webkit-appearance: none; /* Elimina el estilo predeterminado en navegadores WebKit */
  --range-progress: 0;
  appearance: none;
  width: 100%; /* Ancho del input */
  height: 2px; /* Altura de la barra */
  background: #ddd; /* Color de la barra */
  border-radius: 5px; /* Bordes redondeados */
  outline: none; /* Elimina el borde al hacer clic */
  background: linear-gradient(to right, #ddd var(--range-progress), #ddd 0);
  transition: all 0.1s ease-in-out; /* Hace que el cambio de progreso sea suave */
}

input[type="range"].itp-audio-message__input.secondary {
  -webkit-appearance: none; /* Elimina el estilo predeterminado en navegadores WebKit */
  appearance: none;
  width: 100%; /* Ancho del input */
  height: 2px; /* Altura de la barra */
  background: #7367f0; /* Color de la barra */
  border-radius: 5px; /* Bordes redondeados */
  outline: none; /* Elimina el borde al hacer clic */
}

input[type="range"].itp-audio-message__input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Elimina el estilo predeterminado del thumb */
  appearance: none;
  width: 11px; /* Ancho del thumb */
  height: 11px; /* Altura del thumb */
  background: #fff; /* Color del thumb */
  border-radius: 50%; /* Bordes redondeados para que sea un círculo */
  cursor: pointer; /* Cursor al pasar el mouse sobre el thumb */
  border: 1px solid #ffffff;
}

input[type="range"].itp-audio-message__input.secondary::-webkit-slider-thumb {
  -webkit-appearance: none; /* Elimina el estilo predeterminado del thumb */
  appearance: none;
  width: 11px; /* Ancho del thumb */
  height: 11px; /* Altura del thumb */
  background: #7367f0; /* Color del thumb */
  border-radius: 50%; /* Bordes redondeados para que sea un círculo */
  cursor: pointer; /* Cursor al pasar el mouse sobre el thumb */
  border: 1px solid #7367f0;
}

input[type="range"].itp-audio-message__input::-moz-range-thumb {
  width: 11px; /* Ancho del thumb */
  height: 11px; /* Altura del thumb */
  background: #fff; /* Color del thumb */
  border-radius: 50%; /* Bordes redondeados para que sea un círculo */
  cursor: pointer; /* Cursor al pasar el mouse sobre el thumb */
  border: 1px solid #ffffff;
}

input[type="range"].itp-audio-message__input.secondary::-moz-range-thumb {
  width: 11px; /* Ancho del thumb */
  height: 11px; /* Altura del thumb */
  background: #7367f0; /* Color del thumb */
  border-radius: 50%; /* Bordes redondeados para que sea un círculo */
  cursor: pointer; /* Cursor al pasar el mouse sobre el thumb */
  border: 1px solid #7367f0;
}

.itp-audio-message__time-progress {
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-size: 12px;
  margin-top: -2px;
}

.itp-audio-message__time-progress.secondary {
  color: #7367f0;
}

.itp-audio-message__transcript {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
  font-family: 'Source Sans Pro';
  margin: 0.5rem 0 1rem 0;
  position: relative;
  z-index: 1;
}

.itp-audio-message__transcript-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.itp-audio-message__transcript-text.secondary {
  color: #7367f0;
}

.itp-audio-message__transcript-button {
  background: none;
  border: none;
  color: #fff; /* Color para el texto del botón */
  cursor: pointer;
  font-size: 13px;
  margin-left: 0.5rem;
}

.itp-audio-message__transcript-button.secondary {
  color: #7367f0;
}