.itp-my-settings-separator-line {
  border-top: 1px solid #000; /* You can adjust the color and thickness as required */
  width: 80%; /* Adjust this according to your needs. This will set the line to occupy 80% of its parent container width */
  margin: auto; /* This will center the div, and consequently, the horizontal line */
  height: 0; /* To ensure the div doesn't take extra vertical space */
  opacity: 0.1; /* Modify */
}

.itp-my-settings-card-col {
  margin-top: 2.5rem;
  margin-bottom: 2rem !important;
  margin-left: 2rem;
}

.itp-my-settings-card-title {
  color: #002340;
  text-align: left;
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  opacity: 1;
}

.itp-my-settings-card-help-text {
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-top: 10px !important;
}

.itp-my-settings-card-container-centered {
  width: 60%;
  left: 50%;
  height: auto !important;
  overflow: auto;
  transform: translateX(-50%);
  transition: all 0.2s ease;
  position: relative !important;
  overflow-x: hidden;
  margin-top: -60px;
}

.basic-single.switch-caller-id-number.css-b62m3t-container {
  width: 60%;
}
