.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loading-dots span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 0 0 2px;
  background-color: #6d28d9;
  border-radius: 50%;
  animation: loadingDots 1s infinite ease-in-out;
}

@keyframes loadingDots {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.loading-dots span:nth-child(1) {
  animation-delay: -0.32s;
}

.loading-dots span:nth-child(2) {
  animation-delay: -0.16s;
}
