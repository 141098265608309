.h2-color {
  color: #3A3A3A!important;
}

.btn-border {
  background-color: white;
  border-color: #437097;
  border-radius: 20px;
  color: #437097;
  width: 120px!important;
  height: 32px;
  font-family: revert!important;
  font-size: revert!important;
  font-weight: bold;
}

.hover-property:hover {
  background-color: #00264d;
  color: white;
}

.search-property {
  font-family: revert!important;
}

.search-property:hover {
  cursor: pointer;
	border-color: #437097;
}

.workflows-letter-property {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Instrument Sans', sans-serif;
  letter-spacing: 0px;
  color: #3A3A3A;
  opacity: 1;
  text-align: left;
}

.itp-custom-table-container-actions.none {
  display: none!important;
}