.container-label-hover {
  position: relative;
  cursor: pointer;
}

.container-hover-info {
  position: absolute;
  visibility: hidden;
  background-color: #f6f6f9;
  top: -4rem;
  width: auto;
  padding: 8px;
  white-space: normal;
}

.container-label-hover:hover > .container-hover-info {
  visibility: visible;
}

.itp-contact-details-label.aligned-label {
  margin-bottom: 0.8rem;
}

.itp-contact-details-row-select {
  margin-top: 20px !important;
  margin-bottom: 1.3rem;
}

.itp-contact-details-row-select.b-bottom {
  border-bottom: 1px solid #ddd;
}

.itp-contact-details-row-select.b-bottom:focus-within {
  border-bottom: 1px solid #002340;
}
