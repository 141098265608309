.itp-card-flow-search-child {
  max-width: 100%;
}

.itp-card-flow__CardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}