.itp-label-switch__label {
  color: #002340;
  text-align: left;
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  opacity: 1;
}

.itp-label-switch__row {
  font-family: "Instrument Sans";
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-top: 10px !important;
  margin-bottom: 0.5rem;
}

.itp-label-switch__switch-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-left: 0;
  justify-content: flex-end;
}

.itp-label-switch__switch .react-switch-handle {
  width: 17px !important;
  height: 17px !important;
  top: 1px !important;
  left: 1px !important;
}
