.itp-add-note {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.itp-add-note-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.itp-save-notes-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itp-notes {
  font: normal normal 600 18px/24px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-top: 16px;
  margin-bottom: 8px;
}

.itp-note-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.itp-notes-label {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
}

.itp-notes-button {
  margin-left: 1rem;
  white-space: nowrap;
  width: 7rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itp-note-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #70707073;
  border-radius: 8px;
  opacity: 1;
  padding: 12px;
  margin-bottom: 12px;
  transition: all 0.2s ease-in-out;
}

.itp-note-content__title {
  display: flex;
  justify-content: space-between;
}

.itp-note-text {
  font: normal normal normal 12px/14px Instrument Sans;
  letter-spacing: 0px;
  color: rgb(76, 76, 76);
  opacity: 1;
  margin-right: 12px;
}

.itp-edit-note {
  width: 100%;
  margin-top: 12px;
}

.itp-note-content__content {
  display: flex;
  margin-top: 8px;
}

.accordion-header.itp-note-panel > .accordion-button {
  padding: 1rem 0.5rem;
}