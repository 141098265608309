.itp-custom-table-main-thead-styles {
  background: #f3f2f7 0% 0% no-repeat padding-box !important;
  border-radius: 8px 8px 0px 0px;
  opacity: 0.85;
  transition: all 0.2s ease;
}

.form-check-input.itp-custom-table-main-check-header {
  width: 1rem;
  height: 1rem;
}