.itp-data-report-date {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-right: 2rem;
}

.itp-data-report-range-date {
  font: normal normal 600 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.form-select-itp.itp-call-logs-select {
  width: 12rem;
  margin-top: 20px;
}

.itp-tex-back-container.itp-analytics-report {
  top: 5rem;
}

.grphic-title {
  text-align: center;
  margin: 10px 10px 10px 10px;
}

.form-select-itp.width-12r {
  width: 12rem;
}

@media (max-width: 922px) {
  .form-select-itp.width-12r {
    margin-bottom: 1rem;
  }
}

.dropdown-menu.show.itp-responsive-table-action-dropdown {
  inset: -5rem 0 auto auto !important;
}