.row.itp-custom-table-main-container {
  margin-left: 1rem;
  margin-right: 1rem;

  transition: all 0.2s ease-in-out;
}

@media (max-width: 561px) {
  .row.itp-custom-table-main-container,
  .row.itp-custom-table-main-container.margin-3 {
    margin: 0 !important;
  }
}

.row.itp-custom-table-main-container.margin-0 {
  margin: 0;
}

.row.itp-custom-table-main-container.margin-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

/* .card.itp-custom-table-main-card {
  overflow: auto hidden;
} */

.itp-custom-table-main-card {
  overflow: auto hidden;
}

.itp-custom-table-main th,
.itp-custom-table-main td {
  padding: 10px 15px;
  text-align: left;
  white-space: nowrap;
}

.itp-custom-pagination-container {
  padding: 10px 0;
  margin-top: -8px;
}

.itp-custom-table-main-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  margin-bottom: 0;
  width: 100%;
}

.itp-custom-table-main-label {
  text-align: left;
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-bottom: 0;
}

.itp-custom-table-main-p {
  font: normal normal normal 14px/19px Instrument Sans;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin: 0 0 8px 1rem;
  border-radius: 10px;
}

.itp-custom-table-main-select-all-rows-link {
  margin-top: -11px;
}

.card.itp-custom-table-main-cardResponsive {
  background-color: transparent;
  border: 0;
}