.title {
  font: normal normal 600 18px/24px Instrument Sans;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: 0px;
  color: #fff;
}

.message {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  font: normal normal normal 15px/20px Instrument Sans;
}
