.responsive-table {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.row-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-top: 0;
  border-bottom: 0;
  background-color: #fff;
}

.row-item {
  display: flex;
  justify-content: space-between;
  opacity: 0.85;
  transition: all 0.2s ease;
  height: auto;
  align-items: center;
  border: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  min-height: 2rem;
}

.row-item:hover {
  cursor: pointer;
  background-color: rgba(67, 112, 151, 0.2) !important;
}

.row-item:hover > .column-name {
  background-color: transparent !important;
}

.column-name {
  text-align: left;
  white-space: nowrap;
  font-family: Montserrat;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  background: #f3f2f7 0% 0% no-repeat padding-box !important;
  width: 10rem;
  height: auto;
  padding-left: 1rem;
  padding-top: 0.5rem;
  min-height: 2rem;
}

.column-value {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3a3a3acc;
  opacity: 1;
  margin-right: 1rem;
}

.action-dropdown-hover.responsive {
  left: -7rem;
}