.credit-card-logo {
  width: 2rem;
  height: 2rem;
}

.credit-card-logo.discover {
  width: 3rem;
  height: 3rem;
}

.lock-icon {
  margin-right: 0.5rem;
  width: 18px;
  height: 18px;
}

.itp-row-balance {
  margin-left: 1px !important;
}

.itp-billing-balance-container {
  background: rgba(109, 40, 217, 0.1);
  border: 1px solid #f5f5f5;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 0;
}

.itp-billing-balance-text {
  color: #6d28d9;
  font-family: "Source Sans Pro";
  font-size: 16px;
  text-align: left;
  line-height: 1px;
  padding: 5px;
}

.itp-billing-balance {
  color: #6d28d9;
  font-family: "Source Sans Pro";
  font-size: 24px;
  text-align: left;
  line-height: 1px;
  padding: 5px;
}

.itp-billing-card-info {
  background: #3f51b5;
  border: 1px solid #f5f5f5;
  border-radius: 0px;
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.itp-billing-card-number-text {
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-size: 16px;
  text-align: left;
  line-height: 1px;
}

.itp-billing-card-input {
  background: #4e82af;
  border: none;
  border-radius: 0px;
  outline: none;
  width: 100%;
  margin-top: 0.5rem;
  color: black;
}
