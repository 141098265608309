.itp-page-container {
  display: flex;
  /* height: calc(100%) !important; */
  position: absolute;
  width: 100%;
 
}

.itp-width-sidebar {
  margin-left: 233px;
  width: calc(100% - 233px) !important;
  position: relative !important;
  height: 100% !important;
  transition: all 0.5s;
  padding-right: 17px;
  padding-left: 7px;
  padding-top: 7px;
  padding-top: 7px;
  
}

.itp-width-100 {
  width: 100% !important;
  margin-left: 0 !important;
  transition: width 0.2s ease;
}

@media (max-width: 991px) {
  .itp-width-sidebar {
    width: 100% !important;
    margin-left: 0;
    transition: all 0.5s;
  }
}

th {
  font-weight: normal !important;

}

.itp-page-not-found {
  text-align: center;
  margin-top: 50px;
}

.itp-not-found-button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}