.device-emulator-container {
  align-items: center;
}

.mount {
  margin-top: 5rem;
}

.phone {
  width: 300px;
  height: 650px;
  border: solid black 2px;
  border-radius: 36px;
  padding: 20px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.5);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 3rem;
}

.screen {
  height: 80%;
  background-color: #f2f2f2;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message {
  background-color: lightgrey;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  height: auto;
  max-height: 30rem;
  overflow: auto;
}

.input-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 12px;
}

.text-input {
  width: 70%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid grey;
}

.send-button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-weight: bold;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.itp-smartCampaigns__step-5__addEdit-follow-up {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.7rem;
  border-radius: 0.3rem;
}