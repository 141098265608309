.reason-message {
  margin: 0 0 0.75rem 0;
  font: normal normal normal 14px/18px Instrument Sans;
  letter-spacing: 0px;
  color: rgb(58, 58, 58);
}

.reason-message strong {
  color: #002340;
}

.reason-changes-list {
  margin: 0;
  padding: 0 0 0 1.5rem;
  list-style-type: disc;
}

.reason-changes-item {
  margin: 0.25rem 0;
  font: normal normal normal 14px/16px Instrument Sans;
  letter-spacing: 0px;
  color: rgb(58, 58, 58);
}

.reason-changes-item strong {
  color: #002340;
  font-weight: 500;
}

.history-data {
  margin-top: 1rem;
  padding-top: 0.75rem;
  border-top: 1px solid #eee;
}

.history-data p {
  margin: 0 0 0.5rem 0;
  font: normal normal normal 14px/18px Instrument Sans;
  color: #002340;
}

.history-data ul {
  margin: 0;
  padding: 0 0 0 1.5rem;
  list-style-type: disc;
}

.history-data li {
  margin: 0.25rem 0;
  font: normal normal normal 14px/16px Instrument Sans;
  letter-spacing: 0px;
  color: rgb(58, 58, 58);
}

.history-data li strong {
  color: #002340;
  font-weight: 500;
} 