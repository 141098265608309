.itp-input-container {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: #FFFFFF24;
    padding: 5px;
    height: 32px;
    width: 365px;
  }
  
  .itp-search-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    fill: #FFFFFF24;
    cursor: pointer;
  }
  
  .itp-input {
    border: none;
    outline: none;
    flex: 1;
    padding: 5px;
    background-color: transparent;
    color: white;
  }

  .itp-input::placeholder {
    color: #FFFFFF24;
  }
  