.itp-callFlow-container-module {
  background: #a2c7e630 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #85858529;
  border: 0.30000001192092896px solid #0023405c;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
  padding: 6px 16px 12px 16px;
}

#skip_greeting {
  border: 1px solid #437097;
  border-radius: 4px;
}

#text-skip-greeting {
  background-color: #fff;
  color: #0c0c0c;
}

.send-email-help {
  position: relative;
}

.send-email-help:hover > .itp-header-click-icon-hover {
 visibility: visible;
}

.itp-header-click-icon-hover.send-email {
  font: normal normal normal 12px/15px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  padding: 8px;
  text-align: left;
  bottom: 1.5rem;
  width: 10rem;
  left: 0.5rem;
}