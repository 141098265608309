.itp-time-groups-details-title {
  font-size: 14px !important;
  text-align: left !important;
  letter-spacing: 0px !important;
  color: #3a3a3acc !important;
  opacity: 0.8 !important;
}

.itp-time-groups-details-subtitle {
  text-align: left !important;
  font: normal normal 600 24px/32px Instrument Sans !important;
  font-size: 24px !important;
  letter-spacing: 0px !important;
  color: #3a3a3a !important;
  opacity: 1 !important;
  margin-bottom: 0 !important;
}

.itp-time-groups-details-subtitle.time-zone {
  font-size: 14px !important;
}

.itp-add-date-time-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 8px 8px;
  padding-right: 6px;
}
