.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #002340;
  color: #f6f7f7;
  width: 300px;
  border-radius: 0px;
  border-radius: 20px;
  font-size: 14px;
}

.keyboard-close {
  height: 180px;
}

.keyboard-open {
  min-height: 520px;
}
.keyboard-header {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}

.keyboard-top-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px 0px 20px;
}

.keyboard-ellipsis {
  font-size: 30px;
  margin-top: -12px;
}

.keyboard-call-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px 5px 20px;
}

.keyboard-span {
  color: #8ba4b5;
  margin-left: 10px;
  cursor: pointer;
}

.keyboard-buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.keyboard-buttons-item {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  cursor: pointer;
}

.keyboard-buttons-item:hover {
  opacity: 0.9;
}

.keyboard-buttons-item:active {
  opacity: 0.6;
}

.keyboard-buttons-item-mic {
  background: #4caf50;
}

.keyboard-buttons-item-grip {
  background: #005398;
}

.keyboard-buttons-item-hand {
  background: #005398;
}

.keyboard-buttons-item-bye {
  background: #f44336;
}
.keyboard-buttons-item-bye {
  rotate: 225deg;
}

.keyboard-keypad {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.keyboard-keypad button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.keyboard-buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
}

.keyboard-buttons-actions {
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 14px;
  margin-bottom: 20px;
}

.keyboard-transfer {
  background-color: #4caf50;
}

.keyboard-cancel {
  background-color: #f44336;
}

.keyboard-button-keyboard{
  padding: 15px 30px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.keyboard-number{
  width: 100%;
  display: flex;
  position: relative;
  font-size: 16px;
  margin-top: 20px;
  height: 20px;
  margin-bottom: 15px;
  margin-top: 8px;
  /* justify-content: space-between; */
}

.keyboard-number-span{
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
  border: none;
  color: white;
  text-align: center;
  height: 38px;
  font-size: 30px;
}
.keyboard-number-span:focus{
  border: none;
  outline: none;
}

.keyboard-number-delete{
  position: absolute;
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 5px;
  cursor: pointer;
  right: 0px;
}

.keyboard-number-delete div{
  cursor: pointer;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn.dropdown-toggle-split:first-child, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: transparent;
  border: none;
  padding: 0px;
}

.keyboard-call{
  border-radius: 50%;
  background: #4caf50;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  rotate: 120deg;
}

.keyboard-call:hover{
  opacity: 0.9;
}

.keyboard-call:active{
  opacity: 0.6;
}

.keyboard-call-inactive{
  border-radius: 50%;
  background: #4caf50;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.6 !important;
}

.keyboard-call-inactive:hover{
  opacity: 0.6 !important;
}

.keyboard-call-inactive:active{
  opacity: 0.6 !important;
}