.itp-resizing-textarea {
  width: 100%;
  line-height: 24px;
  resize: none;
  overflow-y: hidden;
}

.itp-textarea-wrapper {
  width: 100%;
  position: relative;
}

.itp-resizing-textarea-step4 {
  width: 100%;
  line-height: 24px;
  resize: none;
  overflow-y: hidden;
  min-height: 24px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.itp-resizing-textarea-step4:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}