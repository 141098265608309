.itp-array-tags {
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: 0px;
  color: #437097;
  background: #51a9f01a 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin-right: 8px;
  display: inline-block;
  min-width: 4rem;
  width: auto;
  padding-top: 4px;
  padding-bottom: 4px;
}

.with-arrow {
  background-color: #edf6fd !important;
}
