.itp-select-edit-user {
  width: 12rem !important;
  margin-right: 2rem;
}

.itp-edit-user-nageativeSeparator {
  margin-top: -3rem;
}

.itp-edit-user-permissions-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}