.card {
  margin-bottom: 0px !important;
}

.itp-card-flow-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.itp-card-flow-first-action {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 3.5rem;
}

.itp-card-flow-branchName-border {
  height: 0;
  border: 1px solid rgba(67, 112, 151, 0.4);
}

.ml-50 {
  margin-left: 50% !important;
}

.ml-0 {
  margin-left: 0;
}

.mr-50 {
  margin-right: 50% !important;
}

.mr-0 {
  margin-right: 0;
}

.itp-crad-flow-branchName-border-rect {
  border: 1px solid rgba(67, 112, 151, 0.4);
  width: 0;
  height: 30px;
}

.itp-card-flow-branchName-card.card {
  width: 150px;
  padding: 6px 10px 6px 10px;
  margin-bottom: 0 !important;
  background-color: #437097;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
}

.itp-card-flow-branchName-card.card.deleted {
  background-color: grey;
}

.itp-card-flow-branchName-bottom {
  width: 0;
  height: 30px;
  border: 1px solid rgba(67, 112, 151, 0.4);
}

.cursor-pointer {
  cursor: pointer !important;
}

.itp-card-flow-content.card {
  margin-left: 10px;
  margin-right: 10px;
  width: 240px;
  padding: 18px 0px 18px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0 !important;
  cursor: pointer;
  background: rgba(225, 236, 246, 0.39) 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid rgba(0, 35, 64, 0.39);
  border-radius: 6px;
  position: relative;
}

.itp-card-flow-content.card.selected {
  border: 1px solid black;
}

.itp-card-flow-content.first.card {
  background: #e1ecf6 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #3a3a3a59;
  border-bottom-width: 0.3px;
  border-bottom-color: rgba(58, 58, 58, 0.35);
  border-left-width: 0.3px;
  border-left-color: rgba(58, 58, 58, 0.35);
  border-radius: 6px;
  opacity: 1;
  border-left-color: #437097;
  border-left-width: 3px;
  border-bottom-color: #437097;
  border-bottom-width: 3px;
  width: 250px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.itp-card-flow__card-content-edit-icon {
  position: absolute;
  right: 8px;
  top: 0;
  visibility: hidden;
}

.itp-card-flow-content.card:hover > .itp-card-flow__card-content-edit-icon {
  visibility: visible;
}

.nav-link.itp-card-flow-dropdown.btn:hover {
  background-color: transparent !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.itp-card-flow__card-content-title {
  text-align: center;
  font: normal normal normal 14px/12px Instrument Sans;
  letter-spacing: 0px;
  color: #437097;
  opacity: 1;
}

.itp-card-flow__card-content-title.first {
  text-align: center;
  font: normal normal normal 14px/12px Instrument Sans;
  letter-spacing: 0px;
  color: #002340;
  opacity: 1;
}

.itp-card-flow__card-content-subtitle {
  font-size: 12px;
  color: #888888;
}

.itp-card-flow-many-children {
  margin-bottom: -11px;
  z-index: 1;
}

.add-flow-icon {
  pointer-events: visible !important;
}